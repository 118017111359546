import { css, keyframes } from 'styled-components';

const slideUpAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideUp = css<{ animationDuration?: string }>`
  animation: ${slideUpAnimation} ${props => props.animationDuration || '0.5s'}
    ease;
`;

export { slideUp };
