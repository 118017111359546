import React from 'react';
import styled from 'styled-components';
import chevron from 'images/chevron.svg';

const StyledToolbar = styled.div<{ active: boolean }>`
  position: absolute;
  z-index: 1;
  left: 0;
  top: 50%;
  display: ${p => (p.hidden ? 'none' : 'flex')};
  flex-direction: row-reverse;
  align-items: center;
  transform: ${p =>
    p.active ? 'translate(0px, -50%)' : 'translate(-80px, -50%)'};
  transition: transform 0.4s ease-in-out;

  &.active {
    transform: translateX(0);
  }
`;

const StyledToolbarContent = styled.div<{ active: boolean }>`
  width: 80px;
  min-height: 100px;
  justify-content: space-around;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 15px 10px;
  background-color: ${p => p.theme.colors.white};
  border: 1px solid ${p => p.theme.colors.black};
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: ${p => (p.active ? '2px 2px 2px rgba(0, 0, 0, 0.5)' : 'none')};

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 30px;
    left: -30px;
    transition: box-shadow 0.3s ease-in-out;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  }

  > button {
    margin: 5px 0;
  }
`;

const StyledChevron = styled.button<{ active: boolean }>`
  outline: none;
  position: relative;
  left: -1px;
  appearance: none;
  border: none;
  background-color: ${p => p.theme.colors.white};
  border: 1px solid ${p => p.theme.colors.black};
  border-left-color: #fff;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 35px;
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: ${p => (p.active ? '2px 2px 2px rgba(0, 0, 0, 0.5)' : 'none')};
  z-index: 1;

  img {
    display: block;
    width: 20px;
    height: 20px;
    transition: transform 0.4s;
    transform: ${p => (p.active ? 'rotate(90deg)' : 'rotate(270deg)')};
  }
`;

interface Props {
  hidden: boolean;
}

interface State {
  toolbarActive: boolean;
}

class VMToolbar extends React.Component<Props, State> {
  readonly state = {
    toolbarActive: false,
  };

  toggleToolbar() {
    this.setState({
      toolbarActive: !this.state.toolbarActive,
    });
  }

  render() {
    return (
      <StyledToolbar
        active={this.state.toolbarActive}
        hidden={this.props.hidden}
      >
        <StyledChevron
          active={this.state.toolbarActive}
          onClick={() => this.toggleToolbar()}
        >
          <img src={chevron} alt="open toolbar" />
        </StyledChevron>
        <StyledToolbarContent active={this.state.toolbarActive}>
          {this.props.children}
        </StyledToolbarContent>
      </StyledToolbar>
    );
  }
}

export { VMToolbar };
