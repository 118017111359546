import { css, keyframes } from 'styled-components';

const dotsAnimation = (props: { dotColor?: string; theme: any }) => keyframes`
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: ${props.dotColor || props.theme.colors.darkGrey};
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 ${props.dotColor || props.theme.colors.darkGrey},
      0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 ${props.dotColor || props.theme.colors.darkGrey},
      0.5em 0 0 ${props.dotColor || props.theme.colors.darkGrey};
  }
`;

const dots = css`
  animation: ${dotsAnimation} 1s steps(5, end) infinite;
`;

export { dots };
