import React from 'react';
import styled from 'styled-components';

const StyledErrorID = styled.p`
  font-size: 14px;
  color: ${p => p.theme.colors.black};
`;

type ErrorMessage = {
  error: { error_message: string; error_id: string } | string;
  status?: number;
};

export type AlertsState = {
  message: string | React.ReactNode;
  type: string;
  staticAlert?: boolean;
  dismissable?: boolean;
  setMessage(
    message: ErrorMessage,
    type: string,
    staticAlert?: boolean,
    dismissable?: boolean
  ): void;
  clearMessage(): void;
};

const AlertsContext = React.createContext<AlertsState>({
  message: undefined,
  type: '',
  staticAlert: false,
  dismissable: true,
  setMessage: () => null,
  clearMessage: () => null,
});

const provideAlerts = (WrappedComponent: any) => {
  class ProvideAlerts extends React.Component<
    any,
    {
      message: string | React.ReactNode;
      type: string;
      staticAlert?: boolean;
      dismissable?: boolean;
    }
  > {
    readonly state = {
      message: undefined,
      type: '',
      staticAlert: false,
      dismissable: true,
    };

    createAlert({ error, status }: ErrorMessage) {
      return typeof error === 'string' ? (
        <div>
          {status && `${status} - `}
          {error}
        </div>
      ) : (
        <div>
          <p>
            {status} - {error.error_message}
          </p>
          {error.error_id && (
            <StyledErrorID>
              Error ID: <code>{error.error_id}</code>
            </StyledErrorID>
          )}
        </div>
      );
    }

    setMessage = (
      message: ErrorMessage,
      type: string,
      staticAlert?: boolean,
      dismissable?: boolean
    ): void => {
      const isDismissable =
        dismissable === undefined || dismissable ? true : false;
      this.setState({
        message: this.createAlert(message),
        type,
        staticAlert,
        dismissable: isDismissable,
      });
    };

    clearMessage = (): void => {
      this.setState({
        message: undefined,
        type: '',
        staticAlert: false,
        dismissable: true,
      });
    };

    render() {
      return (
        <AlertsContext.Provider
          value={{
            message: this.state.message,
            type: this.state.type,
            staticAlert: this.state.staticAlert,
            dismissable: this.state.dismissable,
            setMessage: this.setMessage,
            clearMessage: this.clearMessage,
          }}
        >
          <WrappedComponent {...this.props} />
        </AlertsContext.Provider>
      );
    }
  }
  return ProvideAlerts;
};

const withAlerts = (WrappedComponent: any) => (props: any) => (
  <AlertsContext.Consumer>
    {(v: AlertsState) => <WrappedComponent {...props} alerts={v} />}
  </AlertsContext.Consumer>
);

export default AlertsContext;
export { provideAlerts, withAlerts };
