import React from 'react';
import styled from 'styled-components';
import { LoadingIndicator } from 'components';

const StyledLoader = styled.div<{ loaderBackground?: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 10;

  ${props =>
    props.loaderBackground &&
    `
      background-image: url(${props.loaderBackground});
      background-position: center;
      background-size: cover;
    `}
`;
const StyledLoaderBackdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
  }

  > * {
    z-index: 11;
    position: relative;
  }
`;
const StyledAdditionalText = styled.h3`
  color: ${props => props.theme.colors.white};
  margin-top: 0;
`;

const Loader = (props: any) => {
  if (props.hideLoader) return null;

  return (
    <StyledLoader loaderBackground={props.loaderBackground}>
      <StyledLoaderBackdrop>
        <div>
          {props.additionalText && (
            <StyledAdditionalText>{props.additionalText}</StyledAdditionalText>
          )}
          <p>
            <LoadingIndicator />
          </p>
        </div>
      </StyledLoaderBackdrop>
    </StyledLoader>
  );
};

export const PageLoader = Loader;
