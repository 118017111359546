import React from 'react';
import shutdownIcon from 'images/shutdown.svg';
import { KeyboardKeyButton } from 'components';

const VMShutdownButton = (props: any) => (
  <KeyboardKeyButton
    type="button"
    title="Shutdown Virtual Machine"
    aria-label="Shutdown Virtual Machine"
    {...props}
  >
    <img src={shutdownIcon} alt="Shutdown Virtual Machine" />
  </KeyboardKeyButton>
);

export { VMShutdownButton };
