import React from 'react';
import { Overlay } from 'react-portal-overlay';
import styled from 'styled-components';

const StyledOverlay = styled(Overlay)`
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden !important;
  overflow-y: auto !important;
`;
const StyledOverlayContainer = styled.div`
  width: 80%;
  background: ${p => p.theme.colors.white};
  padding: 24px;
  border-radius: 2px;
  max-width: 900px;
  position: relative;
  border: 1px solid ${p => p.theme.colors.black};
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  overflow: auto;

  a {
    color: ${p => p.theme.colors.deepBlue};
  }
`;
const StyledCloseButton = styled.button`
  appearance: none;
  background-color: ${p => p.theme.colors.lightGrey};
  border: 1px solid ${p => p.theme.colors.black};
  color: ${p => p.theme.colors.black};
  transition: all 0.2s;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  position: absolute;
  right: 24px;
  top: 24px;

  &:hover,
  &:focus {
    background-color: ${p => p.theme.colors.grey};
  }
`;
const StyledCloseButtonX = styled.span`
  font-size: 20px;
`;

interface Props {
  isOpen: boolean;
  onClose?: Function;
  children: any;
}

interface State {
  isOpen: boolean;
}

class Modal extends React.Component<Props, State> {
  readonly state = {
    isOpen: false,
  };

  setIsOpen(toggle: boolean) {
    this.setState({ isOpen: toggle });
  }

  close() {
    this.setIsOpen(false);
    if (this.props.onClose) this.props.onClose();
  }

  render() {
    return (
      <StyledOverlay
        open={this.state.isOpen || this.props.isOpen}
        onClose={() => this.close()}
        closeOnEsc
        closeOnClick
        animation={{ duration: 200, easing: 'ease' }}
      >
        <StyledOverlayContainer>
          <StyledCloseButton onClick={() => this.close()}>
            <StyledCloseButtonX>&times;</StyledCloseButtonX>
          </StyledCloseButton>
          {this.props.children}
        </StyledOverlayContainer>
      </StyledOverlay>
    );
  }
}

export { Modal };
