import { css, keyframes } from 'styled-components';

const scaleUpAnimation = keyframes`
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
`;

const scaleUp = css<{ animationDuration?: string }>`
  animation: ${scaleUpAnimation} ${props => props.animationDuration || '0.5s'}
    cubic-bezier(0.39, 0.575, 0.565, 1) both;
`;

export { scaleUp };
