import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthState, withAuth } from 'context/auth';
import { withAlerts, AlertsState } from 'context/alerts';
import { PageLoader } from 'components';

interface Props {
  auth: AuthState;
  component: React.ComponentType;
  checkTerms?: boolean;
  loaderBackground?: string;
  alerts: AlertsState;
}

class ProtectedRouteWrapper extends React.PureComponent<Props> {
  sandboxExists(sandboxId: string): boolean {
    const currentSandbox = this.props.auth.sandboxesAvailable.find(
      sandbox => sandbox.id === sandboxId
    );

    return currentSandbox === undefined ? false : true;
  }

  render() {
    const {
      component: Component,
      auth,
      checkTerms,
      loaderBackground,
      ...rest
    } = this.props;

    // while we fetch the auth-data from google
    if (auth.loggedIn === undefined)
      return (
        <PageLoader
          hideLoader={this.props.alerts.message}
          loaderBackground={loaderBackground}
        />
      );

    return (
      <Route
        {...rest}
        render={props => {
          if (auth.loggedIn === false) {
            return <Redirect to={{ pathname: '/' }} />;
          }

          const sandboxId = props.match.params.sandboxId;
          if (!this.sandboxExists(sandboxId)) {
            return <Redirect to={{ pathname: '/' }} />;
          }

          if (checkTerms && auth.sandbox?.termsAccepted === false) {
            return <Redirect to={{ pathname: `/${sandboxId}` }} />;
          }

          return <Component {...rest} {...props} />;
        }}
      />
    );
  }
}

export const ProtectedRoute = withAuth(withAlerts(ProtectedRouteWrapper));
