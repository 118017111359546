import React from 'react';
import clipboardIcon from 'images/clipboard.svg';
import { VMClipboard, KeyboardKeyButton } from 'components';

interface Props {
  handleClipboardChange: Function;
}

interface State {
  active: boolean;
}

class VMClipboardButton extends React.Component<Props, State> {
  readonly state = {
    active: false,
  };

  readonly clipboard = React.createRef<VMClipboard>();

  constructor(props: any) {
    super(props);
    this.toggleClipboard = this.toggleClipboard.bind(this);
    this.clipboard = React.createRef();
  }

  toggleClipboard() {
    this.setState({ active: !this.state.active }, () => {
      if (!this.state.active) this.clipboard.current?.reset();
    });
  }

  render() {
    return (
      <>
        <KeyboardKeyButton
          type="button"
          title="Toggle clipboard"
          aria-label="Toggle clipboard"
          {...this.props}
          isSelected={this.state.active}
          onClick={() => this.toggleClipboard()}
        >
          <img src={clipboardIcon} alt="clipboard" />
        </KeyboardKeyButton>
        <VMClipboard
          ref={this.clipboard}
          active={this.state.active}
          handleClipboardChange={this.props.handleClipboardChange}
          toggleClipboard={this.toggleClipboard}
        />
      </>
    );
  }
}

export { VMClipboardButton };
