import React from 'react';
import fullscreenIcon from 'images/fullscreen.svg';
import { KeyboardKeyButton } from 'components';

interface Props {
  resizeWindow: any;
}
interface State {
  active: boolean;
}

class VMFullscreenButton extends React.Component<Props, State> {
  readonly state = {
    active: false,
  };

  resize() {
    this.props.resizeWindow();
  }

  /**
   * Handles fullscreen events when fullscreen-button is clicked
   * and also when user exit fullscreen mode by pressing escape
   */
  goFullscreen() {
    const doc = window.document as any;
    const docEl = doc.documentElement as any;

    const fullscreenChangeEvents = [
      'webkitfullscreenchange',
      'mozfullscreenchange',
      'fullscreenchange',
      'MSFullscreenChange',
    ];

    const requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullScreen ||
      docEl.msRequestFullscreen;
    const cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      requestFullScreen.call(docEl);
      this.setState({ active: true }, () => this.resize());
    } else {
      cancelFullScreen.call(doc);
      this.setState({ active: false }, () => this.resize());
    }

    const exitHandler = () => {
      if (
        !doc.fullscreenElement &&
        !doc.mozFullScreenElement &&
        !doc.webkitFullscreenElement &&
        !doc.msFullscreenElement
      ) {
        fullscreenChangeEvents.forEach((event: string) => {
          doc.removeEventListener(event, exitHandler, false);
        });
        this.setState({ active: false }, () => this.resize());
      }
    };

    if (doc.addEventListener) {
      fullscreenChangeEvents.forEach((event: string) => {
        doc.addEventListener(event, exitHandler, false);
      });
    }
  }

  render() {
    return (
      <KeyboardKeyButton
        type="button"
        title="Fullscreen"
        aria-label="Fullscreen"
        {...this.props}
        isSelected={this.state.active}
        onClick={() => this.goFullscreen()}
      >
        <img src={fullscreenIcon} alt="fullscreen" />
      </KeyboardKeyButton>
    );
  }
}

export { VMFullscreenButton };
