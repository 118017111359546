import styled from 'styled-components';

const VMCloseButton = styled.button`
  appearance: none;
  background-color: ${p => p.theme.colors.lightGrey};
  border: 1px solid ${p => p.theme.colors.black};
  transition: all 0.2s;
  margin-left: auto;
  width: 20px;
  height: 20px;
  font-size: 18px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;

  &:hover,
  &:focus {
    background-color: ${p => p.theme.colors.darkGrey};
    color: ${p => p.theme.colors.white};
  }
`;

export { VMCloseButton };
