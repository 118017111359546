import _fetch from 'utils/fetch';
import { AuthState } from 'context/auth';

function makeRequest(method: string, auth: AuthState, url: string, body: any, responseType: string): any {
    return {
        url: `${process.env.REACT_APP_API_HOST}${url}`,
        options: {
            method,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${
                    auth.auth2.currentUser.get().getAuthResponse().id_token
                }`,      
            },
            body: body===null ? body : JSON.stringify(body)
        },
        responseReturnType: responseType
    }
}

function postJson(auth: AuthState, url: string, body: any, responseType: string = 'json'): any {
    return makeRequest('post', auth, url, body, responseType);
}

function putJson(auth: AuthState, url: string, body: any, responseType: string = 'json'): any {
    return makeRequest('put', auth, url, body, responseType);
}

function getJson(auth: AuthState, url: string): any {
    return {
        url: `${process.env.REACT_APP_API_HOST}${url}`,
        options: {
            method: 'get',
            headers: {
                Authorization: `Bearer ${
                    auth.auth2.currentUser.get().getAuthResponse().id_token
                }`,      
            }
        },
        responseReturnType: 'json'
    }
}

export function getSandboxPublicInfo() {
    return _fetch({
        url: `${process.env.REACT_APP_API_HOST}/sandbox-info`,
        options: {
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          },
        },
        responseReturnType: 'json'
      });
}

export function getSandboxInfo(auth: AuthState, sandboxId: string | undefined) {
    const req = getJson(auth, `/sandbox/${sandboxId}/info`);
    return _fetch(req);
}

export function getSandboxTerms(auth: AuthState, sandboxId: string | undefined) {
    const req = getJson(auth, `/sandbox/${sandboxId}/document/tc`);
    return _fetch(req);
}

export function postErrorReport(auth: AuthState, report: any) {
    const req = postJson(auth, '/user/report-error', report, 'text');
    return _fetch(req);
}

export function postAcceptTerms(auth: AuthState, sandboxId: string | undefined) {
    const req = postJson(auth, `/sandbox/${sandboxId}/user/accept-terms`, null, 'text');
    return _fetch(req);
}

export function getVM(auth: AuthState, sandboxId: string | undefined) {
    const req = getJson(auth, `/sandbox/${sandboxId}/vm`)
    return _fetch(req);
}

export function getVMProfiles(auth: AuthState, sandboxId: string | undefined) {
    const req = getJson(auth, `/sandbox/${sandboxId}/vm-profiles`)
    return _fetch(req);
}

export function postStartVM(auth: AuthState, sandboxId: string | undefined, vmID: number) {
    const req = postJson(auth, `/sandbox/${sandboxId}/vm`, vmID)
    return _fetch(req);
}

export function putShutdownVM(auth: AuthState, sandboxId: string | undefined) {
    const req = putJson(auth, `/sandbox/${sandboxId}/vm`, { state: 'shutdown'});
    return _fetch(req);
}
