import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// internal
import { AuthState, withAuth } from 'context/auth';
import { ProfileMenu, ErrorReportingForm } from 'components';
import { textFocusIn } from 'styles/animations';

// resources
import logoWhite from 'images/logo.svg';

const StyledNav = styled.nav`
  padding: 12px 24px;
  background-color: ${p => p.theme.colors.darkGrey};
  display: flex;
`;
const StyledLogo = styled.img`
  display: block;
  vertical-align: middle;
  height: 40px;
  ${textFocusIn};
`;
const StyledLinks = styled.div`
  display: flex;
  width: 100%;
  font-size: 14px;
  margin-right: 16px;
  justify-content: flex-end;
  a {
    padding-left: 20px;
    line-height: 40px;
    font-weight: bold;
  }
`;

interface Props {
  auth: AuthState;
  sandbox: Sandbox | undefined;
  match: any;
}

interface State {
  errorFormActive: boolean;
}

class Nav extends React.PureComponent<Props, State> {
  readonly state = {
    errorFormActive: false,
  };

  constructor(props: any) {
    super(props);

    this.toggleErrorReportingForm = this.toggleErrorReportingForm.bind(this);
  }

  renderLinks() {
    const sandbox = this.props.auth.sandbox;
    if (sandbox === undefined) {
      return null;
    }
    if (!sandbox.termsAccepted) {
      return null;
    }
    return (
      <StyledLinks id="nav-sandbox-desktop-title">
        <Link to={`/${sandbox.id}/vm`}>{sandbox.name} Desktop</Link>
      </StyledLinks>
    );
  }

  toggleErrorReportingForm() {
    this.setState((prevState: any) => {
      return {
        errorFormActive: !prevState.errorFormActive,
      };
    });
  }

  renderProfile() {
    if (!this.props.auth.loggedIn) {
      return null;
    }
    const profile = this.props.auth.auth2.currentUser.get().getBasicProfile();

    return (
      <ProfileMenu
        currentSandbox={this.props.auth.sandbox?.id}
        profile={profile}
        sandboxes={this.props.auth.sandboxesAvailable}
        onSignOut={() => {
          this.props.auth.auth2.signOut();
        }}
        onReportAnError={() => this.toggleErrorReportingForm()}
      />
    );
  }

  renderErrorReportingForm() {
    if (!this.props.auth.loggedIn) {
      return null;
    }
    const profile = this.props.auth.auth2.currentUser.get().getBasicProfile();

    return (
      <ErrorReportingForm
        profile={profile}
        visible={this.state.errorFormActive}
        onClose={this.toggleErrorReportingForm}
      />
    );
  }

  render() {
    const { logo_url = '', title = 'logo' } =
      this.props.auth.sandbox?.themeInformation || {};
    return (
      <>
        <StyledNav id="navigation">
          {}
          <a href="/" id="logo-link">
            {!logo_url && (
              <StyledLogo id="logo" src={logoWhite} alt="Sandbox logo" />
            )}
            {logo_url && <StyledLogo id="logo" src={logo_url} alt={title} />}
          </a>
          {this.renderLinks()}
          {this.renderProfile()}
        </StyledNav>
        {this.renderErrorReportingForm()}
      </>
    );
  }
}

export default withAuth(Nav);
