import React from 'react';
import styled from 'styled-components';
import imageWelcome from 'images/background.jpg';
import { textFocusIn } from 'styles/animations';

const StyledJumbotron = styled.div<{ bg: string; animationDuration?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  min-height: 80vh;
  padding: 24px;
  color: ${p => p.theme.colors.white};
  position: relative;
  ${textFocusIn}

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-image: url(${p => p.bg});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0.6);
    transition: background-image 0.3s ease;
    filter: brightness(70%);
  }
`;
const StyledJumbotronUpperHalf = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
const StyledJumbotronLowerHalf = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
`;

interface Props {
  loading?: boolean;
  upperHalf: React.ReactNode;
  lowerHalf: React.ReactNode;
  className?: string;
  backgroundImage?: string;
}

class JumbotronWrapper extends React.PureComponent<Props, {}> {
  renderContent() {
    const loading = this.props.loading;
    const bgImage =
      loading || !this.props.backgroundImage
        ? imageWelcome
        : this.props.backgroundImage;

    return loading ? null : (
      <StyledJumbotron
        id="jumbotron"
        className={this.props.className}
        bg={bgImage}
        animationDuration="0.2s"
      >
        <StyledJumbotronUpperHalf id="jumbotron-upperhalf">
          {this.props.upperHalf}
        </StyledJumbotronUpperHalf>
        <StyledJumbotronLowerHalf id="jumbotron-lowerhalf">
          {this.props.lowerHalf}
        </StyledJumbotronLowerHalf>
      </StyledJumbotron>
    );
  }

  render() {
    return this.renderContent();
  }
}

export const Jumbotron = JumbotronWrapper;
