import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { withAuth, AuthState } from 'context/auth';

const StyledFooter = styled.footer`
  margin-top: auto;
  color: ${p => p.theme.colors.white};
  background-color: ${p => p.theme.colors.darkGrey};
`;
const StyledFooterContainer = styled.div`
  max-width: 900px;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;
`;

interface Props {
  auth: AuthState;
  sandbox: Sandbox | undefined;
  location: any;
}

class FooterWrapper extends React.PureComponent<Props, {}> {
  isVMPage() {
    const parts = this.props.location.pathname.split('/');

    return parts.pop() === 'vm';
  }
  render() {
    if (this.isVMPage()) return null;

    const copyrightLink =
      this.props.auth.sandbox?.themeInformation?.copyright_url ||
      `${process.env.REACT_APP_COPYRIGHT_URL}`;
    const copyrightText =
      this.props.auth.sandbox?.themeInformation?.copyright ||
      `${process.env.REACT_APP_COPYRIGHT}`;

    return (
      <StyledFooter id="footer">
        <StyledFooterContainer>
          {copyrightLink !== "" &&
            <div id="copyright-text">
              &copy;&nbsp;<a href={copyrightLink}>{copyrightText}</a>
            </div>}
          { copyrightLink === "" &&
            <div id="copyright-text">
              &copy;&nbsp;{copyrightText}
            </div>
          }

        </StyledFooterContainer>
      </StyledFooter>
    );
  }
}

const Footer = withRouter(withAuth(FooterWrapper));

export { Footer };
