import React from 'react';
import extrakeysIcon from 'images/toggleextrakeys.svg';
import { VMExtraKeys, KeyboardKeyButton } from 'components';

interface Props {
  handleKeySend: Function;
}

interface State {
  active: boolean;
}

class VMExtraKeysButton extends React.Component<Props, State> {
  readonly state = {
    active: false,
  };

  readonly extraKeys = React.createRef<VMExtraKeys>();

  constructor(props: any) {
    super(props);
    this.toggleKeys = this.toggleKeys.bind(this);
    this.extraKeys = React.createRef();
  }

  toggleKeys() {
    this.setState({ active: !this.state.active }, () => {
      if (!this.state.active) this.extraKeys.current?.reset();
    });
  }

  render() {
    return (
      <>
        <KeyboardKeyButton
          type="button"
          title="Show extra keys"
          aria-label="Show extra keys"
          {...this.props}
          isSelected={this.state.active}
          onClick={() => this.toggleKeys()}
        >
          <img src={extrakeysIcon} alt="extra keys" />
        </KeyboardKeyButton>
        <VMExtraKeys
          ref={this.extraKeys}
          active={this.state.active}
          handleKeySend={this.props.handleKeySend}
          closeKeys={this.toggleKeys}
        />
      </>
    );
  }
}

export { VMExtraKeysButton };
