import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// internal
import { AuthState, provideAuth } from 'context/auth';
import { AlertsState, provideAlerts } from 'context/alerts';

import { Header, Footer } from 'components/layout';
import { ProtectedRoute, AlertBox } from 'components';
import WelcomePage from 'pages/welcome-page';
import VirtualMachinePage from 'pages/virtual-machine-page';
import SandboxPage from 'pages/sandbox-page';
import imageWelcome from 'images/background.jpg';
import CustomStyles from 'styles/custom-styles';

class App extends React.Component<
  { auth: AuthState; alerts: AlertsState; location: any; match: any },
  {}
> {
  render() {
    return (
      <>
        <CustomStyles />
        <Header />
        <main id="main">
          <Switch>
            <Route exact path="/" component={WelcomePage} />
            <ProtectedRoute
              exact
              path="/:sandboxId?"
              component={SandboxPage}
              loaderBackground={imageWelcome}
            />
            <ProtectedRoute
              exact
              path="/:sandboxId/vm"
              component={VirtualMachinePage}
              checkTerms
              loaderBackground={imageWelcome}
            />
            <Redirect to="/" />
          </Switch>
          <AlertBox
            alertType={this.props.alerts.type}
            floating={!this.props.alerts.staticAlert}
          >
            {this.props.alerts.message}
          </AlertBox>
        </main>
        <Footer />
      </>
    );
  }
}

export default provideAlerts(provideAuth(App));
