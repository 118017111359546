export default function _fetch({
  url,
  options,
  responseReturnType,
}: {
  url: string;
  options?: Object;
  responseReturnType: 'text' | 'json';
}) {
  return fetch(url, options)
    .then(response => {
      if (!response.ok) {
        // Create a new object that contains the response
        // and the error message that response body might contain
        return response.text().then(message => {
          const msg = errorMessage(response.status, message);
          return Promise.reject({ response, message: msg });
        });
      }

      if (response.status === 204) return '';

      return response[responseReturnType]();
    })
    .catch(error => {
      const { response = null, message } = error;

      return Promise.reject(generateError(response, message));
    });
}

function generateError(response: { status: any }, error: string) {
  const res = response || {};
  const { status } = res;
  // Try to parse the error string if it contains an object,
  // otherwise return it
  try {
    const msgObj = JSON.parse(error);
    return { status, error: msgObj };
  } catch (e) {
    return { status, error };
  }
}

function errorMessage(statusCode: number, message: string) {
  const messages: { [key: number]: string } = {
    502: 'Service is under maintenance. Please try again later.',
    // code: message
  };

  return messages.hasOwnProperty(statusCode) ? messages[statusCode] : message;
}
