import React from 'react';
import styled from 'styled-components';
import extrakeysIcon from 'images/toggleextrakeys.svg';
import ctrl from 'images/ctrl.svg';
import alt from 'images/alt.svg';
import esc from 'images/esc.svg';
import windows from 'images/windows.svg';
import { VMCloseButton as ExtraKeysClose, KeyboardKeyButton } from 'components';

/**
 * Get correct KeyboardEvent.code values
 * @see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code/code_values
 */
const { default: KEYSYMS } = require('novnc-core/lib/input/keysym.js');

const ExtraKeysWrapper = styled.div<{ active: boolean }>`
  resize: none;
  padding: 15px;
  width: 400px;
  height: 200px;
  background-color: ${p => p.theme.colors.white};
  border: 1px solid ${p => p.theme.colors.black};
  position: absolute;
  left: ${p => (p.active ? '120px' : '-400px')};
  opacity: ${p => (p.active ? 1 : 0)};
  transition: all 0.3s ease, opacity 0.1s ease;
  border-radius: 2px;
  z-index: ${p => (p.active ? '2' : '-1')};
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  flex-direction: column;
  display: flex;
`;
const ExtraKeysHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid ${p => p.theme.colors.black};
  color: ${p => p.theme.colors.black};

  img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
`;
const ExtraKeysContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

interface Props {
  handleKeySend: Function;
  closeKeys: Function;
  active: boolean;
}

interface State {
  value: string;
  toggledKeys?: { [key: string]: string };
}

class VMExtraKeys extends React.Component<Props, State> {
  readonly state = {
    value: '',
    toggledKeys: {},
  };

  constructor(props: any) {
    super(props);
    this.handleKey = this.handleKey.bind(this);
  }

  isKeySelected(code: string) {
    return this.state.toggledKeys.hasOwnProperty(code);
  }

  /**
   * When we close the extra keys -window,
   * we need to send key "release" event to RFB
   */
  reset() {
    const selectedKeys: { [key: string]: string } = this.state.toggledKeys;
    this.setState({ toggledKeys: {} }, () => {
      Object.entries(selectedKeys).forEach(([code, keysym]: [string, string]) =>
        this.handleKey({ keysym, code }, false)
      );
    });
  }

  resetAndClose() {
    this.reset();
    this.props.closeKeys();
  }

  /**
   * Send a key event to the server.
   * @see https://github.com/novnc/noVNC/blob/master/docs/API.md#rfbsendkey
   * @param {Object} obj
   * @param {(number|string)} obj.keysym - A long specifying the RFB keysym to send. Can be 0 if a valid code is specified.
   * @param {string} obj.code - A DOMString specifying the physical key to send.
   * Valid values are those that can be specified to KeyboardEvent.code.
   * If the physical key cannot be determined then null shall be specified.
   * @param {boolean} toggle - Is the pressed button toggle-button
   */
  handleKey(obj: { keysym?: number | string; code: string }, toggle: boolean) {
    const { keysym, code } = obj;

    /**
     * A boolean specifying if a press or a release event should be sent.
     * If omitted then both a press and release event are sent.
     */
    let down = true;

    if (toggle) {
      let keys = this.state.toggledKeys;
      if (keys.hasOwnProperty(code)) {
        // If key is already been pressed, remove it from state and send down-event
        const { [code]: remove, ...rest }: { [key: string]: string } = keys;
        keys = rest;
        down = false;
      } else {
        keys = { ...keys, ...{ [code]: keysym } };
      }
      this.setState(
        {
          toggledKeys: keys,
        },
        this.props.handleKeySend(keysym, code, down)
      );
    } else {
      // Single button press sends both press and release events (down === undefined)
      this.props.handleKeySend(keysym, code);
    }
  }

  render() {
    return (
      <ExtraKeysWrapper active={this.props.active}>
        <ExtraKeysHeader>
          <img src={extrakeysIcon} alt="extra keys" />
          <span>Extra Keys</span>
          <ExtraKeysClose onClick={() => this.resetAndClose()}>
            &times;
          </ExtraKeysClose>
        </ExtraKeysHeader>
        <ExtraKeysContainer>
          <KeyboardKeyButton
            isSelected={this.isKeySelected('ControlLeft')}
            onClick={() =>
              this.handleKey(
                {
                  keysym: KEYSYMS.XK_Control_L,
                  code: 'ControlLeft',
                },
                true
              )
            }
            title="Toggle Ctrl"
          >
            <img src={ctrl} alt="Ctrl" />
          </KeyboardKeyButton>
          <KeyboardKeyButton
            isSelected={this.isKeySelected('AltLeft')}
            onClick={() =>
              this.handleKey(
                {
                  keysym: KEYSYMS.XK_Alt_L,
                  code: 'AltLeft',
                },
                true
              )
            }
            title="Toggle Alt"
          >
            <img src={alt} alt="Alt" />
          </KeyboardKeyButton>
          <KeyboardKeyButton
            isSelected={this.isKeySelected('MetaLeft')}
            onClick={() =>
              this.handleKey(
                {
                  keysym: KEYSYMS.XK_Meta_L,
                  code: 'MetaLeft',
                },
                true
              )
            }
            title="Toggle Windows"
          >
            <img src={windows} alt="Windows" />
          </KeyboardKeyButton>
          <KeyboardKeyButton
            onClick={() =>
              this.handleKey(
                {
                  keysym: KEYSYMS.XK_Escape,
                  code: 'Escape',
                },
                false
              )
            }
            title="Send Esc"
          >
            <img src={esc} alt="Esc" />
          </KeyboardKeyButton>
        </ExtraKeysContainer>
      </ExtraKeysWrapper>
    );
  }
}

export { VMExtraKeys };
