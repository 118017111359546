import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { withAuth, AuthState } from 'context/auth';

const StylesCss = createGlobalStyle<{ customCss: string }>`
  ${props => props.customCss}
`;

interface Props {
  auth: AuthState;
  sandbox: Sandbox | undefined;
}

class Styles extends React.PureComponent<Props, {}> {
  render() {
    const { custom_css: customCss = '' } =
      this.props.auth.sandbox?.themeInformation || {};

    return <StylesCss customCss={customCss} />;
  }
}

const CustomStyles = withAuth(Styles);

export default CustomStyles;
