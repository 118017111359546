import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import _fetch from 'utils/fetch';
import { withAlerts, AlertsState } from 'context/alerts';

export type AuthState = {
  loggedIn: boolean | undefined;
  sandboxesAvailable: Sandbox[];
  auth2: gapi.auth2.GoogleAuth;
  sandboxId?: string;
  sandbox?: Sandbox | undefined;
  acceptSandboxTerms(): void;
};

interface Props extends RouteComponentProps {
  alerts: AlertsState;
  auth: AuthState;
}

const AuthContext = React.createContext<AuthState>({
  loggedIn: undefined,
  sandboxesAvailable: [],
  auth2: null as any,
  acceptSandboxTerms: () => null,
});

const provideAuth = (WrappedComponent: any) => {
  class ProvideAuth extends React.Component<
    Props,
    {
      loggedIn: boolean | undefined;
      sandboxesAvailable: Sandbox[];
      auth2: gapi.auth2.GoogleAuth;
      sandboxId?: string;
      sandbox?: Sandbox | undefined;
    }
  > {
    readonly state = {
      loggedIn: undefined,
      sandboxesAvailable: [],
      auth2: null as any,
      sandboxId: '',
      sandbox: undefined,
    };

    componentDidMount() {
      let scriptElement = document.createElement('script');
      scriptElement.src = 'https://apis.google.com/js/api.js';
      document.head.append(scriptElement);

      scriptElement.onload = () => {
        const gapi = (window as any).gapi;
        gapi.load('auth2', async () => {
          try {
            const auth2 = await gapi.auth2.init({
              client_id: process.env.REACT_APP_GOOGLE_CLIENTID,
              fetch_basic_profile: true,
              scope: 'email profile',
              ux_mode: 'popup'
            });
            this.setState({
              auth2,
            });

            auth2.isSignedIn.listen(this.updateSignInStatus);
            // handle initial signin state
            this.updateSignInStatus(auth2.isSignedIn.get());
          } catch (err) {
            this.props.alerts.setMessage(
              {
                error: `Sorry, but we're having trouble signing you in.
                  Please make sure that you have enabled third party cookies and try again by reloading the page.
                  If this doesn't work, you might want to contact your administrator.`,
              },
              'danger',
              false,
              false
            );
            this.props.history.push('/');
          }
        });
      };
    }

    componentDidUpdate(prevProps: Props) {
      if (
        prevProps.location.pathname !== this.props.location.pathname &&
        this.state.loggedIn
      ) {
        this.checkCurrentSandbox(this.state.sandboxesAvailable);
      }
    }

    checkCurrentSandbox(sandboxes: Sandbox[]) {
      const parts = this.props.location.pathname.split('/');
      if (parts.length < 2) {
        if (this.state.sandboxId !== '') {
          // we navigated away from sandbox
          this.setState({
            sandboxId: '',
            sandbox: undefined,
          });
        }
        return;
      }

      const sandboxId = parts[1];
      if (this.state.sandboxId === sandboxId && !!this.state.sandbox) {
        // nothing to update
        return;
      }

      const sandbox = sandboxes.find(sandbox => sandbox.id === sandboxId);
      this.setState({
        sandboxesAvailable: sandboxes,
        loggedIn: true,
        sandboxId,
        sandbox,
      });
    }

    updateSignInStatus = (signedIn: boolean) => {
      // if signing out, sign out immediately
      if (!signedIn) {
        // user is no longer logged in
        this.setState({
          loggedIn: false,
        });
        // cleanup sandbox information
        this.setState({
          sandboxId: '',
          sandbox: undefined,
        });
      } else {
        // if signing in, wait until we get the sandboxes before marking the user as logged in
        if (this.state.loggedIn !== undefined) {
          this.setState({
            loggedIn: undefined,
          });
        }

        // TODO: change the request to only fetch the available sandboxes and their states
        this.fetchToken(this.state.auth2.currentUser.get());
      }
    };

    /**
     * When user accepts terms in sandbox page we need to update them manually here.
     * This is because we don't refresh the page so the auth state won't update.
     */
    acceptSandboxTerms = (): void => {
      const { id, name, themeInformation } = this.state.sandbox || {
        id: '',
        name: '',
        themeInformation: {},
      };
      const sandbox = {
        id,
        name,
        termsAccepted: true,
        themeInformation,
      };
      this.setState({ sandbox });
    };

    async fetchToken(user: gapi.auth2.GoogleUser) {
      // if there was an previous error message on display from previous logon attempt
      //  clear it first
      this.props.alerts.clearMessage();

      const url = `${process.env.REACT_APP_API_HOST}/user/list-sandboxes`;

      try {
        const json = await _fetch({
          url,
          options: {
            method: 'get',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${user.getAuthResponse().id_token}`,
            },
          },
          responseReturnType: 'json',
        });
        const sandboxes = json.map((sandbox: any) => ({
          id: sandbox.id,
          name: sandbox.name,
          termsAccepted: sandbox.terms_accepted,
          themeInformation: sandbox.theme_information,
        }));
        this.checkCurrentSandbox(sandboxes);
      } catch (errorObject) {
        // sign user out
        this.state.auth2.signOut();
        // display error message
        this.props.alerts.setMessage(errorObject, 'danger');
      }
    }

    render() {
      return (
        <AuthContext.Provider
          value={{
            loggedIn: this.state.loggedIn,
            sandboxesAvailable: this.state.sandboxesAvailable,
            auth2: this.state.auth2,
            sandboxId: this.state.sandboxId,
            sandbox: this.state.sandbox,
            acceptSandboxTerms: this.acceptSandboxTerms,
          }}
        >
          <WrappedComponent {...this.props} />
        </AuthContext.Provider>
      );
    }
  }
  return withRouter(withAlerts(ProvideAuth));
};

const withAuth = (WrappedComponent: any) => (props: any) => (
  <AuthContext.Consumer>
    {(v: AuthState) => <WrappedComponent {...props} auth={v} />}
  </AuthContext.Consumer>
);

const withAuthorization = (condition: (auth: AuthState) => boolean) => (
  WrappedComponent: any
) => {
  class WithAuthorization extends React.Component<any, {}> {
    render() {
      // render wrapped component only if logged in is resolved and condition is satisfied
      return (
        <AuthContext.Consumer>
          {(v: AuthState) =>
            v.loggedIn !== undefined &&
            condition(v) && <WrappedComponent {...this.props} />
          }
        </AuthContext.Consumer>
      );
    }
  }
  return WithAuthorization;
};

export default AuthContext;
export { provideAuth, withAuth, withAuthorization };
