import React from 'react';
import styled from 'styled-components';
import { AuthState, withAuth } from 'context/auth';
import { AlertsState } from 'context/alerts';
import {
  Button,
  ContentArea,
  NoVNC,
  LoadingDots,
  PageLoader,
  UnstyledButton,
} from 'components';
import { slideUp, textFocusIn } from 'styles/animations';
import { getVM, getVMProfiles, postStartVM, putShutdownVM } from 'utils/api';

const StyledVirtualMachineSelector = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;
const StyledStartVMButton = styled(Button)`
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  max-width: 160px;
  color: ${p => p.theme.colors.darkGrey};
  border: 1px solid ${p => p.theme.colors.grey};
  background-color: ${p => p.theme.colors.white};

  :hover {
    box-shadow: none;
  }
`;
const StyledVirtualMachineCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 258px;
  margin: 8px;
  padding: 12px;
  border: 1px solid ${p => p.theme.colors.midGrey};
  ${slideUp}
`;
const StyledVMName = styled.p`
  font-size: 20px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
`;
const StyledVMProperties = styled.ul`
  margin: 20px 0;
  padding-left: 28px;
`;
const StyledVMStarting = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  text-align: center;
`;
const StyledReconnectText = styled.p`
  ${textFocusIn}
  ${UnstyledButton} {
    margin: 20px 5px;
  }
`;
const StyledShutdownButton = styled(UnstyledButton)`
  margin: 0 5px;
  text-decoration: underline;
  color: ${p => p.theme.colors.deepBlue};
  transition: color 0.2s;
  font: inherit;

  &:hover,
  &:focus {
    color: ${p => p.theme.colors.darkBlue};
  }
`;
const StyledParagraph = styled.p`
  ${slideUp}
`;

interface Props {
  sandbox: Sandbox;
  sandboxId: string;
  auth: AuthState;
  history: any;
  match: any;
  alerts: AlertsState;
}

interface VMType {
  id: number;
  name: string;
  description: string;
}
interface State {
  loadingVM: boolean;
  startingVM: boolean;
  shuttingDownVM: boolean;
  vmTypes: VMType[];
  vmAddress: string;
  vmStatus: string;
  vmPassword: string;
}

class VirtualMachinePage extends React.Component<Props, State> {
  readonly state = {
    loadingVM: true,
    startingVM: false,
    shuttingDownVM: false,
    vmTypes: [],
    vmAddress: '',
    vmStatus: '',
    vmPassword: '',
  };

  private vmTimeout: number = 0;

  constructor(props: any) {
    super(props);
    this.setErrorAndShutdownVM = this.setErrorAndShutdownVM.bind(this);
    this.loadVM = this.loadVM.bind(this);
    this.shutdownVM = this.shutdownVM.bind(this);
  }

  async componentDidMount() {
    this.loadVM();
  }

  /**
   * If VNC connection throws an error when establishing connection,
   * set error message to UI and shutdown the current VM.
   */
  setErrorAndShutdownVM(errorMsg: string): void {
    this.setState(
      {
        vmStatus: 'TERMINATED',
      },
      () => {
        this.props.alerts.setMessage({ error: errorMsg }, 'danger', true);
        this.shutdownVM();
      }
    );
  }

  /**
   * Check Virtual Machine status.
   * Statuses:
   *  NOT_FOUND = Load and list all available Virtual Machines for user
   *  RUNNING = Virtual Machine and VNC connection are ready
   *  all other statuses = Virtual Machine is ready but the VNC connection is still pending.
   *  Call loadVM() again until connection is established.
   */
  async loadVM() {
    const sandboxId = this.props.auth.sandbox?.id;
    try {
      const vm = await getVM(this.props.auth, sandboxId);

      if (vm.vm_status === 'NOT_FOUND') {
        // no vm running
        await this.fetchAvailableVMTypes();
        this.setState({
          loadingVM: false,
          startingVM: false,
        });
        return false;
      } else {
        if (vm.vm_status !== 'RUNNING') {
          this.setState({
            vmStatus: vm.vm_status,
          });

          this.setVMLoadTimer();
        } else {
          // we have a machine
          // use only the first one in the list
          this.setState({
            vmStatus: vm.vm_status,
            vmPassword: vm.password,
            vmAddress: `${vm.vnc_path}`,
            loadingVM: false,
            startingVM: false,
          });
        }
      }
    } catch (err) {
      this.setState(
        {
          loadingVM: false,
        },
        () => this.props.alerts.setMessage(err, 'danger', true)
      );
    }
  }

  /**
   * Poll virtual machine status so that we know when the VNC connection is ready
   */
  setVMLoadTimer() {
    this.vmTimeout = window.setTimeout(() => this.loadVM(), 2000);
  }

  async fetchAvailableVMTypes() {
    const sandboxId = this.props.auth.sandbox?.id;
    try {
      const vmTypes = await getVMProfiles(this.props.auth, sandboxId);
      this.setState({
        vmTypes,
      });
    } catch (err) {
      this.props.alerts.setMessage(err, 'danger', true);
    }
  }

  async startVM(vmId: number) {
    this.setState({
      startingVM: true,
    });
    const sandboxId = this.props.auth.sandbox?.id;
    try {
      const startVMResponse = await postStartVM(this.props.auth, sandboxId, vmId);

      if (startVMResponse.status !== 'ok') throw new Error('Could not load VM');

      this.loadVM();
    } catch (err) {
      this.setState(
        {
          loadingVM: false,
          startingVM: false,
        },
        () =>
          this.props.alerts.setMessage(
            err instanceof Error ? { error: err.message } : err,
            'danger',
            true
          )
      );
    }
  }

  async shutdownVM() {
    this.setState({
      shuttingDownVM: true,
      startingVM: false,
    });
    const sandboxId = this.props.auth.sandbox?.id;
    try {
      const shutdownVMResponse = await putShutdownVM(this.props.auth, sandboxId);

      if (shutdownVMResponse.status === 'ok') {
        this.setState(
          {
            vmStatus: '',
            shuttingDownVM: false,
          },
          this.fetchAvailableVMTypes
        );
        this.props.history.replace(`/${sandboxId}/vm`);
      } else {
        throw new Error(
          'Could not shut down current Virtual Machine. Contact your administrator.'
        );
      }
    } catch (err) {
      this.setState(
        {
          vmStatus: '',
          shuttingDownVM: false,
          loadingVM: false,
        },
        () => {
          this.props.alerts.setMessage(
            err instanceof Error ? { error: err.message } : err,
            'danger',
            true
          );
          this.fetchAvailableVMTypes();
        }
      );
      this.props.history.replace(`/${sandboxId}/vm`);
    }
  }

  render() {
    if (!this.state.loadingVM && this.state.vmStatus === 'RUNNING') {
      return (
        <>
          <NoVNC
            address={this.state.vmAddress}
            password={this.state.vmPassword}
            shuttingDownVM={this.state.shuttingDownVM}
            setErrorAndShutdownVM={this.setErrorAndShutdownVM}
            loadVM={this.loadVM}
            shutdownVM={this.shutdownVM}
          >
            {this.state.shuttingDownVM && (
              <PageLoader
                additionalText={'Closing virtual machine connection'}
              />
            )}
          </NoVNC>
        </>
      );
    }

    return (
      <ContentArea>
        <h1>Virtual machine</h1>
        {this.state.shuttingDownVM && (
          <LoadingDots>Closing connection, please wait</LoadingDots>
        )}
        {this.state.loadingVM && (
          <LoadingDots>Checking virtual machine status</LoadingDots>
        )}
        {this.state.startingVM && !this.props.alerts.message && (
          <StyledVMStarting>
            <p>
              <strong>Machine is starting</strong>
            </p>
            <LoadingDots>
              {this.state.vmStatus !== 'RUNNING_VNC_NOT_READY' && (
                <span>
                  Your machine will be ready in a moment and you'll be connected
                  to it
                </span>
              )}
              {this.state.vmStatus === 'RUNNING_VNC_NOT_READY' && (
                <span>Virtual Machine ready. Finalizing connection</span>
              )}
            </LoadingDots>
            {this.state.vmStatus === 'RUNNING_VNC_NOT_READY' && (
              <StyledReconnectText>
                If this takes more than two minutes please
                <StyledShutdownButton
                  type="button"
                  onClick={() => {
                    clearTimeout(this.vmTimeout);
                    this.shutdownVM();
                  }}
                >
                  shutdown
                </StyledShutdownButton>
                and restart the virtual machine.
              </StyledReconnectText>
            )}
          </StyledVMStarting>
        )}
        {!this.state.loadingVM &&
          !this.state.startingVM &&
          !this.state.shuttingDownVM && (
            <>
              <StyledParagraph>
                Select your virtual machine type
              </StyledParagraph>
              <StyledVirtualMachineSelector>
                {this.state.vmTypes.map((vmType: VMType) => (
                  <StyledVirtualMachineCard key={vmType.id}>
                    <StyledVMName>{vmType.name}</StyledVMName>
                    <StyledVMProperties>
                      {vmType.description &&
                        JSON.parse(vmType.description).map((b: string) => (
                          <li key={b}>{b}</li>
                        ))}
                    </StyledVMProperties>
                    <StyledStartVMButton
                      onClick={() => this.startVM(vmType.id)}
                    >
                      Start machine
                    </StyledStartVMButton>
                  </StyledVirtualMachineCard>
                ))}
              </StyledVirtualMachineSelector>
            </>
          )}
      </ContentArea>
    );
  }
}

export default withAuth(VirtualMachinePage);
