import React from 'react';
import ReactMarkdown from 'react-markdown';
import { AuthState, withAuth } from 'context/auth';
import { LoadingDots } from 'components';
import { getSandboxPublicInfo, getSandboxInfo } from 'utils/api';

import styled from 'styled-components';

import iconIndividual from 'images/icon-3.png';
import iconGene from 'images/icon-2.png';
import iconPheno from 'images/icon-4.png';

const StyledColContainer = styled.div`
  display: flex;

  @media (max-width: 800px) {
    flex-direction: column-reverse;
  }
`;
const StyledCol = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  :first-child {
    padding-left: 0;
  }
  :last-child {
    padding-right: 0;
  }
  a {
    text-decoration: none;
    color: ${p => p.theme.colors.darkGrey};
    :hover {
      text-decoration: underline
    }
  }
`;
const StyledScoreCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px;
  min-width: 360px;
`;
const StyledScoreCardIcon = styled.img`
  max-height: 68px;
  margin-right: 8px;
`;
const StyledScoreCardContent = styled.div`
  padding: 8px;
`;
const StyledScoreCardNumber = styled.div`
  font-size: 48px;
  font-weight: bold;
  font-family: ${p => p.theme.fonts.heading};
  line-height: 1;
`;
const StyledScoreCardLabel = styled.div`
  font-size: 18px;
`;

interface Props {
  auth: AuthState;
  sandboxId: string | undefined;
}

interface State {
  infoLoading: boolean;
  infoTitle: string;
  infoDescription: string;
  infoIndividuals: string | undefined;
  infoVariants: string | undefined;
  infoPhenotypes: string | undefined;
}

const formatNumber = (n: number) => {
  return n>0 ? n.toLocaleString("fr-FR") : undefined;
}

class SandboxInfoWrapper extends React.Component<Props, State> {
  readonly state = {
    infoLoading: false,
    infoTitle: '',
    infoDescription: '',
    infoIndividuals: undefined,
    infoVariants: undefined,
    infoPhenotypes: undefined
  };

  buildStateFromResponse(info: any) {
    return {
      infoTitle: info.title,
      infoDescription: info.description,
      infoIndividuals: formatNumber(info.individuals),
      infoPhenotypes: formatNumber(info.phenotypes),
      infoVariants: formatNumber(info.variants),
      infoLoading: false,
    };
  }

  async loadPublicInfo() {
    try {
      const info = await getSandboxPublicInfo();
      this.setState(this.buildStateFromResponse(info));
    } catch (err) {
      this.setState({ infoLoading: false });
    }
  }

  async loadInfo() {
    try {
      const info = await getSandboxInfo(this.props.auth, this.props.sandboxId);
      this.setState(this.buildStateFromResponse(info));
    } catch (err) {
      // auth error, load public info
      this.setState({ infoLoading: false }, this.loadPublicInfo);
    }
  }

  doLoad() {
    var loader = this.props.auth.auth2!==null ? this.loadInfo : this.loadPublicInfo; 
    this.setState({ infoLoading: true }, loader);
  }

  componentDidMount() {
    this.doLoad();
  }

  componentDidUpdate(props: Props) {
    if(props.sandboxId !== this.props.sandboxId && !this.state.infoLoading) {
      this.doLoad();
    }
  }

  render() {
    if (this.state.infoLoading) {
      return <StyledColContainer>
                <StyledCol>
                  <LoadingDots dotColor="black"></LoadingDots>
                </StyledCol>          
              </StyledColContainer>;
    }

    return (
      <>
      <h1>{this.state.infoTitle}</h1>
      <StyledColContainer>
        <StyledCol>
          <ReactMarkdown source={this.state.infoDescription}/>
        </StyledCol>
        <StyledCol>
          { this.state.infoIndividuals ? (<StyledScoreCard>
            <StyledScoreCardIcon src={iconIndividual} />
            <StyledScoreCardContent>
            <StyledScoreCardNumber>{this.state.infoIndividuals}</StyledScoreCardNumber>
              <StyledScoreCardLabel>Individuals</StyledScoreCardLabel>
            </StyledScoreCardContent>
          </StyledScoreCard>) : null }
          { this.state.infoVariants ? (<StyledScoreCard>
            <StyledScoreCardIcon src={iconGene} />
            <StyledScoreCardContent>
            <StyledScoreCardNumber>{this.state.infoVariants}</StyledScoreCardNumber>
              <StyledScoreCardLabel>Variants</StyledScoreCardLabel>
            </StyledScoreCardContent>
          </StyledScoreCard>) : null }
          { this.state.infoPhenotypes ? (<StyledScoreCard> 
            <StyledScoreCardIcon src={iconPheno} />
            <StyledScoreCardContent>
              <StyledScoreCardNumber>{this.state.infoPhenotypes}</StyledScoreCardNumber>
              <StyledScoreCardLabel>Phenotypes</StyledScoreCardLabel>
            </StyledScoreCardContent>
          </StyledScoreCard>) : null }
        </StyledCol>
      </StyledColContainer>
    </>    
    );
  }
}

const SandboxInfo = withAuth(SandboxInfoWrapper);

export { SandboxInfo };
