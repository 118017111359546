import React from 'react';
import styled from 'styled-components';
import RFB from 'novnc-core';
import debounce from 'utils/debounce';
import {
  VMToolbar,
  VMClipboardButton,
  VMExtraKeysButton,
  VMFullscreenButton,
  VMDisconnectButton,
  VMShutdownButton,
  UnstyledButton,
} from 'components';

/**
 * Get the most reliable keysym value we can get from a key event
 */
const { getKeysym } = require('novnc-core/lib/input/util.js');

const StyledNoVNCFrame = styled.div`
  background-color: ${p => p.theme.colors.white};
  width: 100%;
  height: 100%;
  visibility: visible;
  opacity: 1;
  position: absolute;
  display: flex;

  &.fullscreen {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: 100% !important;
    height: 100% !important;
  }
`;
const StyledReconnectWrapper = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
const StyledReconnectButton = styled(UnstyledButton)`
  margin-left: 5px;
  text-decoration: underline;
  color: ${p => p.theme.colors.deepBlue};
  transition: color 0.2s;
  font: inherit;

  &:hover,
  &:focus {
    color: ${p => p.theme.colors.darkBlue};
  }
`;
const StyledTextArea = styled.textarea`
  height: 0;
  width: 0;
  position: absolute;
  left: -100px;
  top: -100px;
`;

interface Props {
  address: string;
  password: string;
  setErrorAndShutdownVM: Function;
  loadVM: Function;
  shutdownVM: Function;
  shuttingDownVM: boolean;
}

interface State {
  loading: boolean;
  cleanDisconnect: boolean;
  textAreaValue: string;
  controlKeyPressed: boolean;
  controlKeySym: number;
  shiftKeySym: number;
  metaKeySym: number;
  altKeySym: number;
}

export class NoVNC extends React.Component<Props, State> {
  readonly state = {
    loading: true,
    cleanDisconnect: false,
    textAreaValue: '',
    controlKeyPressed: false,
    controlKeySym: 0,
    shiftKeySym: 0,
    metaKeySym: 0,
    altKeySym: 0,
  };

  private fbRef = React.createRef<HTMLDivElement>();
  readonly textarea = React.createRef<HTMLTextAreaElement>();
  private rfb: RFB = null as any;
  private element: any;
  private errorMsg: string = '';
  private debouncedFn: Function | undefined;
  private canvasSet: boolean = false;
  private canvas: HTMLCanvasElement | undefined;
  private tokenRefreshTried: boolean = false;

  constructor(props: any) {
    super(props);

    this.handleClipboardChange = this.handleClipboardChange.bind(this);
    this.handleSpecialKeySend = this.handleSpecialKeySend.bind(this);
    this.resizeWindow = this.resizeWindow.bind(this);
    this.disconnect = this.disconnect.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.shutdown = this.shutdown.bind(this);
  }

  createRFBInstance() {
    this.setState({
      cleanDisconnect: false,
    });
    this.rfb = new RFB(this.element, this.props.address, {
      credentials: {
        password: this.props.password,
      },
    });
    this.rfb.background = '#ffffff';
    this.rfb.resizeSession = true;

    this.rfb.addEventListener(
      'connect',
      () => {
        this.setState({ loading: false })
        this.tokenRefreshTried = false;
      },
      false
    );

    /**
     * The disconnect event is fired when the connection has been terminated.
     * The detail property is an Object that contains the property clean.
     * clean is a boolean indicating if the termination was clean or not.
     * In the event of an unexpected termination or an error clean will be set to false.
     * @see https://github.com/novnc/noVNC/blob/master/docs/API.md#disconnect
     * @event disconnect
     */
    this.rfb.addEventListener(
      'disconnect',
      ({ detail }: any) => {
        const { clean } = detail;
        if (clean === false) {
          if (!this.tokenRefreshTried) {
            this.tokenRefreshTried = true;
            this.props.loadVM().then(() => {
              this.reconnect();
            })
          } else {
            const errorMsg =
              this.errorMsg ||
              'Error when connecting. Please try again or contact your administrator.';
            this.tokenRefreshTried = false;
            this.props.setErrorAndShutdownVM(errorMsg);
          }
        } else {
          this.cleanDisconnect();
        }
      },
      false
    );

    /**
     * The credentialsrequired event is fired when the server requests
     * more credentials than were specified to RFB().
     * @see https://github.com/novnc/noVNC/blob/master/docs/API.md#securityfailure
     * @event credentialsrequired
     * @fires disconnect
     */
    this.rfb.addEventListener(
      'credentialsrequired',
      ({ detail }: any) => {
        this.errorMsg =
          'Server authentication failure. Please try again or contact your administrator.';
      },
      false
    );

    /**
     * The securityfailure event is fired when the handshaking process
     * with the server fails during the security negotiation step.
     * @see https://github.com/novnc/noVNC/blob/master/docs/API.md#securityfailure
     * @event securityfailure
     * @fires disconnect
     */
    this.rfb.addEventListener(
      'securityfailure',
      ({ detail }: any) => {
        //const { status, reason } = detail;
        this.errorMsg =
          'Security failure. Please try again or contact your administrator.';
      },
      false
    );
  }

  cleanDisconnect() {
    this.setState({
      cleanDisconnect: true,
    });
  }

  reconnect() {
    this.canvasSet = false;
    this.createRFBInstance();
    this.debouncedFn = undefined;
  }

  resizeWindow() {
    this.element.classList.toggle('fullscreen');
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.element = this.fbRef.current;
    if (this.element) {
      this.createRFBInstance();
    }
  }

  shutdown() {
    if(window.confirm('Are you sure to shut down the virtual machine?')) {
      this.props.shutdownVM();
    }
  }

  disconnect() {
    this.cleanDisconnect();
    this.rfb.disconnect();
  }

  componentDidUpdate() {
    if (!this.element) return;

    this.canvas = this.element.querySelector('canvas');
    /**
     * Avoid binding eventListeners multiple times
     */
    if (this.canvas && this.canvasSet === false) {
      this.canvasSet = true;
      /**
       * When user clicks canvas (or sets focus to it in any way),
       * focus to textarea
       */
      this.canvas.addEventListener('focus', () => this.setCanvasFocus());
    }
  }

  setCanvasFocus() {
    this.textarea.current?.focus();
  }

  componentWillUnmount() {
    this.canvas?.removeEventListener('focus', () => this.setCanvasFocus());
  }

  handleClipboardChange(
    event: React.ChangeEvent<HTMLTextAreaElement> | null
  ): void {
    /* signal to React not to nullify the event object */
    if (event !== null) event.persist();

    if (!this.debouncedFn) {
      this.debouncedFn = debounce(
        () =>
          this.rfb.clipboardPasteFrom(event !== null ? event.target.value : ''),
        300
      );
    }
    this.debouncedFn();
  }

  /**
   * Handle special key button send to NoVNC.
   * @param {number} keysym - A long specifying the RFB keysym to send. Can be 0 if a valid code is specified.
   * @param {string} code - A DOMString specifying the physical key to send.
   * Valid values are those that can be specified to KeyboardEvent.code.
   * If the physical key cannot be determined then null shall be specified.
   * @param {boolean} [down] - A boolean specifying if a press or a release event should be sent.
   * If omitted then both a press and release event are sent.
   */
  handleSpecialKeySend(keysym: number, code: string, down?: boolean) {
    this.rfb.sendKey(keysym, code, down);
  }

  handleKeyDown(event: React.KeyboardEvent<HTMLTextAreaElement>): void {
    let controlKeyRequired = false;
    event.persist();

    /**
     * We want keep focus in canvas
     */
    event.preventDefault();

    /**
     * Use Mac Command key as control
     */
    if (navigator.platform.match("Mac") && event.key === 'Meta') {
      event.key = 'Control'
    }

    /**
     * Track control button status and send it only if next event isn't AltGraph
     */
    if (event.key === 'Control' && !this.state.controlKeyPressed) {
      this.setState({
        controlKeyPressed: true,
        controlKeySym: getKeysym(event)
      });
    } else if (this.state.controlKeyPressed) {
      if (event.key !== 'AltGraph') {
        this.handleSpecialKeySend(this.state.controlKeySym, 'Control', true);
      }
    }

    /**
     * Sync host machines clipboard to vm if ctrl(mac command) + b is pressed
     */
    if (event.key.toLowerCase() === 'b' && (navigator.platform.match("Mac") ? event.metaKey : event.ctrlKey)) {
      navigator.clipboard.readText().then(text => {
        this.rfb.clipboardPasteFrom(text);
      });
    }

    /**
     * Track if control (or Mac command) is used as part of shortcut
     */
    if ((navigator.platform.match("Mac") && event.metaKey) ||  event.ctrlKey) {
      controlKeyRequired = true;
    }

    /**
     * Track Meta & Shift buttons keysyms
     */
    if (event.key === 'Shift') {
      this.setState({
        shiftKeySym: getKeysym(event)
      });
    } else if (event.key === 'Meta') {
      this.setState({
        metaKeySym: getKeysym(event)
      });
    } else if (event.key === 'Alt') {
      this.setState({
        altKeySym: getKeysym(event)
      });
    } else if (event.key !== 'Dead') {
      // Don't send metakeys to NoVNC without actual input
      if (!['Alt', 'AltGraph', 'Control', 'Shift', 'Meta'].includes(event.key)) {

        // Send control just before other characters if needed
        if (controlKeyRequired) {
          this.handleSpecialKeySend(this.state.controlKeySym, 'Control', true);
        }

        // Send shift just before other characters if needed
        if (event.shiftKey) {
          this.handleSpecialKeySend(this.state.shiftKeySym, 'Shift', true);
        }

        // Send alt just before other characters if needed
        if (event.altKey) {
          this.handleSpecialKeySend(this.state.altKeySym, 'Alt', true);
        }

        // Send meta just before other characters if needed
        if (event.metaKey && !navigator.platform.match("Mac")) {
          this.handleSpecialKeySend(this.state.metaKeySym, 'Meta', true);
        }

        this.rfb.sendKey(getKeysym(event), event.key);
      }
    }

    /**
     * Release meta, alt & shift
     */
     if (event.metaKey && !navigator.platform.match("Mac")) {
      this.handleSpecialKeySend(this.state.metaKeySym, 'Meta', false);
    }
    if (event.altKey) {
      this.handleSpecialKeySend(this.state.altKeySym, 'Alt', false);
    }
    if (event.shiftKey) {
      this.handleSpecialKeySend(this.state.shiftKeySym, 'Shift', false);
    }

    /**
     * Release control if needed
     */
    if (controlKeyRequired || this.state.controlKeyPressed) {
      this.handleSpecialKeySend(this.state.controlKeySym, 'Control', false);
      this.setState({
        controlKeyPressed: false
      });
    }
  }

  /**
   * Get current entered character from textarea and save it to state
   */
  handleOnChange(event: React.ChangeEvent<HTMLTextAreaElement>): void {
    this.setState({ textAreaValue: event.target.value });
  }

  /**
   * Send user entered characted to NoVNC
   */
  handleKeyUp(event: React.KeyboardEvent<HTMLTextAreaElement>): void {
    event.persist();

    /**
     * get textarea value and empty it afterwards so that we always get
     * latest character key press user has entered
     * If pressed key is dead, get latest character from textarea and
     * generate hexadecimal number from it so that NoVNC can handle it correctly,
     */
    const textAreaValue = this.state.textAreaValue;
    if (event.key === 'Dead') {
      this.textarea.current?.focus();
      const hex = textAreaValue.charCodeAt(0).toString(16);
      const result = `0x${'0000'.substring(0, 4 - hex.length)}${hex}`;

      this.rfb.sendKey(Number(result), null);
      this.setState({ textAreaValue: '' });
    }
  }

  render() {
    return (
      <StyledNoVNCFrame ref={this.fbRef}>
          <>
            <VMToolbar hidden={this.props.shuttingDownVM}>
              {!this.state.loading && (
                <>
                  {!this.state.cleanDisconnect && (
                    <>
                      <VMClipboardButton
                        handleClipboardChange={this.handleClipboardChange}
                      />
                      <VMExtraKeysButton
                        handleKeySend={this.handleSpecialKeySend}
                      />
                    </>
                  )}
                  <VMFullscreenButton resizeWindow={this.resizeWindow} />
                  {!this.state.cleanDisconnect && (
                    <VMDisconnectButton disconnect={this.disconnect} />
                  )}
                </>
              )}
              <VMShutdownButton onClick={this.shutdown} />
            </VMToolbar>
            {this.props.children}
          </>
        {!this.state.loading &&
          this.state.cleanDisconnect &&
          !this.props.shuttingDownVM && (
            <StyledReconnectWrapper>
              Connection closed. Please
              <StyledReconnectButton
                type="button"
                onClick={() => this.reconnect()}
              >
                reconnect
              </StyledReconnectButton>
              .
            </StyledReconnectWrapper>
          )}
        <StyledTextArea
          value={this.state.textAreaValue}
          onChange={this.handleOnChange}
          onKeyUp={this.handleKeyUp}
          onKeyDown={this.handleKeyDown}
          ref={this.textarea}
        />
      </StyledNoVNCFrame>
    );
  }
}
