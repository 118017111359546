import { css, keyframes } from 'styled-components';

const focusInAnimation = keyframes`
  0% {
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    filter: blur(0px);
    opacity: 1;
  }
`;

const textFocusIn = css<{ animationDuration?: string }>`
  animation: ${focusInAnimation} ${props => props.animationDuration || '0.3s'}
    cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
`;

export { textFocusIn };
