import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { UnstyledButton } from 'components';
import letterIcon from 'images/letter.svg';
import logoutIcon from 'images/logout.svg';
import boxIcon from 'images/box.svg';
import chevronIcon from 'images/chevron.svg';

const StyledProfileElement = styled.div`
  margin-left: auto;
  display: inline-block;
  position: relative;
`;
const StyledProfileButton = styled(UnstyledButton)<{ isOpen: boolean }>`
  outline: none;
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: ${p => (p.isOpen ? '2px 2px 0 0' : '2px')};
  color: ${p => (p.isOpen ? 'inherit' : p.theme.colors.white)};
  background-color: ${p => (p.isOpen ? p.theme.colors.white : 'transparent')};
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 2px 2px rgba(255, 255, 255, 0.4);
  }
`;
const StyledProfilePicture = styled.img`
  margin-left: 4px;
  border-radius: 2px;
  height: 32px;
`;
const StyledButtonCaret = styled.span`
  margin-left: 4px;
  width: 10px;
`;
const StyledButtonCaretImage = styled.img<{ isOpen: boolean }>`
  width: 10px;
  filter: ${p => (p.isOpen ? 'none' : 'invert(100%)')};
`;
const StyledProfileName = styled.div`
  margin-bottom: 4px;
`;
const StyledProfileMenu = styled.div<{ isOpen: boolean }>`
  display: ${p => (p.isOpen ? 'block' : 'none')};
  position: absolute;
  z-index: 20;
  right: 0;
  border-radius: 2px 0 2px 2px;
  background-color: ${p => p.theme.colors.white};
  min-width: 250px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
`;
const StyledProfileMenuItem = styled.div`
  padding: 14px;
  border-bottom: 1px solid ${p => p.theme.colors.midGrey};

  &:last-child {
    border-bottom: none;
    border-radius: 0 0 2px 2px;
  }
`;
const StyledProfileMenuItemClickable = styled(UnstyledButton)<{
  borderTop?: boolean;
}>`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 14px;
  width: 100%;
  border-bottom: 1px solid ${p => p.theme.colors.midGrey};
  font-size: 14px;

  &:last-child {
    border-bottom: none;
    border-radius: 0 0 2px 2px;
  }

  ${props =>
    props.borderTop &&
    `
    border-top: 1px solid ${props.theme.colors.midGrey};
  `}

  &:hover {
    background-color: ${p => p.theme.colors.lightGrey};
    text-decoration: underline;
  }
`;
const StyledProfileMenuItemLink = styled(Link)`
  font-size: 14px;
  align-items: center;
  cursor: pointer;
  margin: 10px 0px 10px 10px;
  display: inline-flex;
  color: ${p => p.theme.colors.darkGrey};

  &.active {
    font-weight: bold;
  }

  &:last-child {
    border-bottom: none;
    border-radius: 0 0 2px 2px;
  }
`;
const StyledProfileMenuItemSmallContent = styled.div`
  font-size: 12px;
  color: ${p => p.theme.colors.midGrey};
`;
const StyledMenuImage = styled.img`
  width: 20px;
  margin-right: 7px;
`;
const StyledSandboxesContainer = styled.div`
  margin: 14px;
`;
const StyledSandboxesLabel = styled.p`
  font-size: 14px;
  margin-bottom: 14px;
  font-weight: bold;
`;

interface Props {
  profile: gapi.auth2.BasicProfile;
  sandboxes: Sandbox[];
  onSignOut: () => void;
  onReportAnError: () => void;
  currentSandbox?: string;
}

interface State {
  isOpen: boolean;
}

export class ProfileMenu extends React.Component<Props, State> {
  readonly state = {
    isOpen: false,
  };

  readonly ref = React.createRef<HTMLButtonElement>();

  handleButtonClick = (event: React.MouseEvent) => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  handleDocumentClick = (event: MouseEvent | TouchEvent) => {
    if (this.ref.current) {
      if (
        event.target instanceof HTMLElement &&
        this.ref.current.contains(event.target)
      ) {
        return;
      }
    }
    if (this.state.isOpen) {
      this.setState({
        isOpen: false,
      });
    }
  };

  componentDidMount() {
    // install global event listeners
    document.addEventListener('click', this.handleDocumentClick, false);
    document.addEventListener('touchend', this.handleDocumentClick, false);
  }

  componentWillUnmount() {
    // uninstall global event listeners
    document.removeEventListener('click', this.handleDocumentClick, false);
    document.removeEventListener('touchend', this.handleDocumentClick, false);
  }

  render() {
    const {
      sandboxes,
      profile,
      onSignOut,
      onReportAnError,
      currentSandbox,
    } = this.props;
    return (
      <StyledProfileElement id="nav-profile-menu">
        <StyledProfileButton
          ref={this.ref}
          isOpen={this.state.isOpen}
          onClick={this.handleButtonClick}
        >
          <StyledProfilePicture src={profile.getImageUrl()} />
          <StyledButtonCaret>
            <StyledButtonCaretImage
              isOpen={this.state.isOpen}
              src={chevronIcon}
              alt="open menu"
            />
          </StyledButtonCaret>
        </StyledProfileButton>
        <StyledProfileMenu isOpen={this.state.isOpen}>
          <StyledProfileMenuItem>
            <StyledProfileName>{profile.getName()}</StyledProfileName>
            <StyledProfileMenuItemSmallContent>
              <div>{profile.getEmail()}</div>
              {/*<div>Some other information of the current user</div>*/}
            </StyledProfileMenuItemSmallContent>
          </StyledProfileMenuItem>
          <StyledSandboxesContainer>
            <StyledSandboxesLabel>Your Sandboxes:</StyledSandboxesLabel>
            {sandboxes.map(sandbox => (
              <StyledProfileMenuItemLink
                className={currentSandbox === sandbox.id ? 'active' : ''}
                key={sandbox.id}
                to={`/${sandbox.id}`}
              >
                <StyledMenuImage src={boxIcon} alt={sandbox.name} />
                {sandbox.name}
              </StyledProfileMenuItemLink>
            ))}
          </StyledSandboxesContainer>
          <StyledProfileMenuItemClickable borderTop onClick={onReportAnError}>
            <StyledMenuImage src={letterIcon} alt="Report an error" />
            Report an error
          </StyledProfileMenuItemClickable>
          <StyledProfileMenuItemClickable onClick={onSignOut}>
            <StyledMenuImage src={logoutIcon} alt="Sign out" />
            Sign out
          </StyledProfileMenuItemClickable>
        </StyledProfileMenu>
      </StyledProfileElement>
    );
  }
}
