import React from 'react';
import styled from 'styled-components';

import loadingIndicator from 'images/loading.svg';

const StyledLoader = styled.img`
  filter: drop-shadow(1px 0px 0px rgba(255, 255, 255, 0.8));
`;

export const LoadingIndicator = () => (
  <StyledLoader src={loadingIndicator} alt="loading" />
);
