import React from 'react';
import styled from 'styled-components';

const StyledLabel = styled.label`
  display: block;
  max-width: 100%;
  margin-bottom: 5px;
  color: ${props => props.theme.colors.black};
  font-size: inherit;
`;
const StyledTextarea = styled.textarea`
  background-color: ${props => props.theme.colors.white};
  border-radius: 0;
  appearance: none;
  color: ${props => props.theme.colors.black};
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: 14px;
  width: 100%;
  border: 1px solid ${p => p.theme.colors.grey};
  margin-bottom: 20px;

  &:active:not([disabled]),
  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    border: 1px solid ${p => p.theme.colors.darkGrey};
  }

  &[disabled] {
    background-color: #d8d8d8;
  }
`;

const StyledRequiredMark = styled.span`
  color: #d0021b;
`;

export const Textarea = ({ labelText, required, ...props }: any) => (
  <>
    <StyledLabel>
      {labelText}
      {required && <StyledRequiredMark> *</StyledRequiredMark>}
    </StyledLabel>
    <StyledTextarea {...props} />
  </>
);
