import styled, { css } from 'styled-components';

export const Button = styled.button`
  display: inline-block;
  text-decoration: none;
  border: none;
  outline: none;
  transition: background-color 0.2s ease;
  color: ${p => p.theme.colors.white};
  background-color: ${p => p.theme.colors.deepBlue};
  padding: 12px 18px;
  border-radius: 20px;
  min-width: 140px;
  font-weight: bold;
  text-align: center;
  font-size: 14px;

  :hover,
  :active,
  :focus {
    color: ${p => p.theme.colors.white};
    background-color: ${p => p.theme.colors.darkBlue};
  }

  :disabled {
    color: ${p => p.theme.colors.midGrey};
    background-color: ${p => p.theme.colors.lightGrey};
    :hover {
      box-shadow: none;
    }
  }
`;

export const UnstyledButtonStyles = css`
  appearance: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: none;
  color: inherit;
  font-size: 14px;
`;

export const UnstyledButton = styled.button<{ appendStyles?: any }>`
  ${UnstyledButtonStyles}
  ${props => props.appendStyles}
`;

export const KeyboardKeyButton = styled(UnstyledButton)<{
  isSelected?: boolean;
  hideButton?: boolean;
}>`
  outline: none;
  display: ${p => (p.hideButton ? 'none' : 'block')};
  color: #aaa;
  padding: 0;
  width: 44px;
  height: 41px;
  margin: 5px;
  background-color: #eff0f2;
  border-radius: 4px;
  border-top: 1px solid #e2dddd;
  box-shadow: inset 0 0 25px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;

  :active {
    color: #888;
    background: #ccc;
    border-top: 1px solid #eee;
    box-shadow: inset 0 0 25px #ddd, 0 0 3px #333;
  }

  ${props =>
    props.isSelected &&
    `
    color: #888;
    background: #ccc;
    border-top: 1px solid #eee;
    box-shadow: inset 0 0 25px #ddd, 0 0 3px #333;
  `};

  img {
    width: 30px;
    height: auto;
    position: relative;
    top: 2px;
  }
`;
