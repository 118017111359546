import React from 'react';
import disconnectIcon from 'images/disconnect.svg';
import { KeyboardKeyButton } from 'components';

interface Props {
  disconnect(): void;
}

class VMDisconnectButton extends React.Component<Props, {}> {
  render() {
    return (
      <KeyboardKeyButton
        type="button"
        title="Disconnect"
        aria-label="Disconnect"
        {...this.props}
        onClick={() => this.props.disconnect()}
      >
        <img src={disconnectIcon} alt="disconnect" />
      </KeyboardKeyButton>
    );
  }
}

export { VMDisconnectButton };
