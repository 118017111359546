import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { AuthState, withAuth } from 'context/auth';
import { AlertsState, withAlerts } from 'context/alerts';
import { LoadingDots } from 'components';
import { getSandboxTerms } from 'utils/api';

const StyledHeader = styled.h3`
  margin-top: 0;
`;
interface Props {
  auth: AuthState;
  alerts: AlertsState;
  loadTermsOnInit: boolean;
}

interface State {
  termsTitle: string;
  terms: string;
  termsLoading: boolean;
}

class SandboxTermsWrapper extends React.Component<Props, State> {
  readonly state = {
    termsTitle: '',
    terms: '',
    termsLoading: false,
  };

  componentDidMount() {
    if (
      this.props.auth.sandbox?.termsAccepted === false ||
      this.props.loadTermsOnInit === true
    ) {
      this.setState(
        {
          termsLoading: true,
        },
        () => this.loadTerms()
      );
    }
  }

  async loadTerms() {
    try {
      const terms = await getSandboxTerms(this.props.auth, this.props.auth.sandbox?.id);

      this.setState({
        termsTitle: terms.title,
        terms: terms.body,
        termsLoading: false,
      });
    } catch (err) {
      this.setState(
        {
          termsLoading: false,
        },
        () => this.props.alerts.setMessage(err, 'danger')
      );
    }
  }

  render() {
    if (this.state.termsLoading)
      return <LoadingDots dotColor="white">Loading sandbox terms</LoadingDots>;

    return (
      <>
        {!this.props.loadTermsOnInit && (
          <StyledHeader>Accept the Terms of Use to continue</StyledHeader>
        )}
        <h4>{this.state.termsTitle}</h4>
        <ReactMarkdown source={this.state.terms} />
        {this.props.children}
      </>
    );
  }
}

const SandboxTerms = withAuth(withAlerts(SandboxTermsWrapper));

export { SandboxTerms };
