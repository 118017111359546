import React from 'react';
import styled, { css } from 'styled-components';
import { slideUp } from 'styles/animations';
import { UnstyledButton } from 'components';
import { withAlerts, AlertsState } from 'context/alerts';

interface AlertType {
  [key: string]: any;
}

const AlertTypes: AlertType = {
  danger: css`
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  `,
  success: css`
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  `,
  warning: css`
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  `,
};

function getAlertStyles(type: string) {
  return AlertTypes[type];
}

const StyledAlertBox = styled.div<{ alertType?: string; floating?: boolean }>`
  ${slideUp}
  position: relative;
  padding: 12px 12px 12px 24px;
  border-radius: 2px;
  margin: 1em auto;
  border: 1px solid transparent;
  min-height: 70px;
  max-width: 600px;
  min-width: 600px;
  display: flex;
  align-items: center;
  box-shadow: ${props =>
    props.floating ? '0px 2px 10px 0 rgba(0, 0, 0, 0.2)' : 'none'};

  /* info-box */
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;

  ${props => props.alertType && getAlertStyles(props.alertType)}
  ${props =>
    props.floating &&
    `
    position: absolute;
    left: 0;
    right: 0;
  `}
`;
const StyledAlertContent = styled.div`
  margin-right: 12px;
`;
const StyledAlertDismiss = styled(UnstyledButton)`
  font: inherit;
  align-self: flex-start;
  margin-left: auto;
  padding: 12px;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;

  &:hover {
    color: #000;
    text-decoration: none;
    opacity: 0.75;
  }

  > span {
    cursor: pointer;
  }
`;

interface Props {
  alertType: string;
  floating?: boolean;
  alerts: AlertsState;
}

interface State {
  active: boolean;
}

class AlertBoxWrapper extends React.Component<Props, State> {
  dismiss() {
    this.props.alerts.clearMessage();
  }

  render() {
    if (!this.props.alerts.message) return null;
    return (
      <StyledAlertBox {...this.props}>
        <StyledAlertContent>{this.props.children}</StyledAlertContent>
        {this.props.alerts.dismissable && (
          <StyledAlertDismiss
            onClick={() => this.dismiss()}
            type="button"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </StyledAlertDismiss>
        )}
      </StyledAlertBox>
    );
  }
}

const AlertBox = withAlerts(AlertBoxWrapper);

export { AlertBox };
