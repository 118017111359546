import React from 'react';
import styled from 'styled-components';
import clipboardIcon from 'images/clipboard.svg';
import { VMCloseButton as ClipboardClose } from 'components';

const ClipboardWrapper = styled.div<{ active: boolean }>`
  resize: none;
  padding: 15px;
  width: 400px;
  height: 200px;
  background-color: ${p => p.theme.colors.white};
  border: 1px solid ${p => p.theme.colors.black};
  position: absolute;
  left: ${p => (p.active ? '120px' : '-400px')};
  opacity: ${p => (p.active ? 1 : 0)};
  transition: all 0.3s ease, opacity 0.1s ease;
  border-radius: 2px;
  z-index: ${p => (p.active ? '3' : '-1')};
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  -webkit-backface-visibility: hidden;
  flex-direction: column;
  display: flex;
`;

const ClipboardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;

  img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
`;

const ClipboardTextArea = styled.textarea`
  border: 1px solid ${p => p.theme.colors.black};
  border-radius: 2px;
  height: 80px;
  width: 100%;
  margin-top: 15px;
  font-size: 14px;
  resize: none;
`;

const ClipboardClearButton = styled.button`
  background-color: ${p => p.theme.colors.lightGrey};
  appearance: none;
  padding: 5px;
  border-radius: 2px;
  font-size: 12px;
  margin-left: auto;
  margin-top: 15px;
  transition: all 0.2s;
  border: 1px solid ${p => p.theme.colors.black};

  &:hover,
  &:focus {
    background-color: ${p => p.theme.colors.darkGrey};
    color: ${p => p.theme.colors.white};
  }
`;

interface Props {
  handleClipboardChange: Function;
  toggleClipboard: Function;
  active: boolean;
}

interface State {
  value: string;
}

class VMClipboard extends React.Component<Props, State> {
  readonly state = {
    value: '',
  };

  constructor(props: any) {
    super(props);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(event: React.ChangeEvent<HTMLTextAreaElement>): void {
    this.setState(
      { value: event.target.value },
      this.props.handleClipboardChange(event)
    );
  }

  reset() {
    this.setState({ value: '' }, this.props.handleClipboardChange(null));
  }

  render() {
    return (
      <ClipboardWrapper active={this.props.active}>
        <ClipboardHeader>
          <img src={clipboardIcon} alt="clipboard" />
          <span>Clipboard</span>
          <ClipboardClose
            onClick={() => {
              this.reset();
              this.props.toggleClipboard();
            }}
          >
            &times;
          </ClipboardClose>
        </ClipboardHeader>
        <ClipboardTextArea
          value={this.state.value}
          onChange={this.handleOnChange}
          placeholder="Write or paste text here.."
        />
        <ClipboardClearButton onClick={() => this.reset()}>
          Clear textarea
        </ClipboardClearButton>
      </ClipboardWrapper>
    );
  }
}

export { VMClipboard };
