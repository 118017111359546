import React from 'react';
import { AuthState, withAuth } from 'context/auth';
import { Button } from './button';

interface Props {
  auth: AuthState;
}
interface State {
  signInInProgress: boolean;
}

class loginButton extends React.Component<Props, State> {
  readonly state = {
    signInInProgress: false,
  };

  readonly buttonRef = React.createRef<HTMLButtonElement>();

  componentDidMount() {
    this.props.auth.auth2.attachClickHandler(
      this.buttonRef.current,
      {},
      () => {
        // success
      },
      () => {
        // failed
        this.setState({ signInInProgress: false });
      }
    );
  }

  render() {
    return (
      <Button
        ref={this.buttonRef}
        disabled={this.state.signInInProgress}
        onClick={() => {
          this.setState({ signInInProgress: true });
        }}
      >
        Login
      </Button>
    );
  }
}

const LoginButton = withAuth(loginButton);

export { LoginButton };
