import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colors: {
    white: '#ffffff',
    black: '#000000',
    deepBlue: '#3500D3',
    darkBlue: '#190061',
    lightBlue: '#A3D3FF',
    darkGrey: '#343a40',
    midGrey: '#aaaaaa',
    grey: '#cccccc',
    lightGrey: '#eeeeee',

    darkGreyTransparent: '#1a1d20a0',
  },
  fonts: {
    heading: 'Finlandica',
  },
};

export { theme };
