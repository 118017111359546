import styled from 'styled-components';
import { dots } from 'styles/animations';

const LoadingDots = styled.p<{ dotColor?: string }>`
  &:after {
    color: ${props => props.dotColor || props.theme.colors.darkGrey};
    content: ' .';
    ${dots}
  }
`;

export { LoadingDots };
