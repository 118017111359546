import { createGlobalStyle } from 'styled-components';

import finlandicaRegularWoff2 from 'fonts/finlandica-regularWeb.woff2';
import finlandicaRegularWoff from 'fonts/finlandica-regularWeb.woff';
import finlandicaRegularTtf from 'fonts/finlandica-regularWeb.ttf';
import finlandicaBoldWoff2 from 'fonts/finlandica-boldWeb.woff2';
import finlandicaBoldWoff from 'fonts/finlandica-boldWeb.woff';
import finlandicaBoldTtf from 'fonts/finlandica-boldWeb.ttf';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Finlandica';
    src: url(${finlandicaRegularWoff2}) format('woff2'),
         url(${finlandicaRegularWoff}) format('woff'),
         url(${finlandicaRegularTtf}) format('ttf');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Finlandica';
    src: url(${finlandicaBoldWoff2}) format('woff2'),
         url(${finlandicaBoldWoff}) format('woff'),
         url(${finlandicaBoldTtf}) format('ttf');
    font-weight: bold;
    font-style: normal;
  }
  * {
    box-sizing: border-box;
  }
  *:before,
  *:after {
    box-sizing: border-box;
  }
  html {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    font-size: 10px;
  }
  body {
    height: 100%;
    margin: 0;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    color: ${p => p.theme.colors.darkGrey};
  }
  body,
  html {
    min-height: 100%;
  }
  div#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  main {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  a {
    text-decoration: none;
    color: #ffffff;
    :hover {
      text-decoration: underline
    }
  }
  h1,h2,h3,h4 {
    font-family: Finlandica;
  }
  h1 {
    font-size: 48px;
  }
  h2 {
    font-size: 36px;
  }
  h3 {
    font-size: 26px
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 16px;
  }
  button {
    cursor: pointer;
  }
  ul,
  ol {
    margin-top: 10px;
    margin-bottom: 10px;
    li {
      margin-bottom: 5px;
    }
    ul,
    ol {
      margin-bottom: 0;
    }
  }
`;

export default GlobalStyles;
