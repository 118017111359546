import React from 'react';
import styled from 'styled-components';

const StyledCheckmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  border: 1px solid ${p => p.theme.colors.grey};
  background-color: ${p => p.theme.colors.lightGrey};

  :after {
    content: '';
    position: absolute;
    display: none;
    left: 5px;
    top: 1px;
    width: 6px;
    height: 12px;
    border: solid ${p => p.theme.colors.white};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

const StyledChildren = styled.span``;

const StyledLabel = styled.label`
  display: block;
  position: relative;
  padding-left: 32px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;

  :hover {
    input ~ ${StyledCheckmark} {
      background-color: ${p => p.theme.colors.grey};
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 0;
    height: 0;
    margin: 0;

    :checked ~ ${StyledCheckmark} {
      background-color: ${p => p.theme.colors.darkGrey};
      background-color: ${p => p.theme.colors.deepBlue};
      :after {
        display: block;
      }
    }
    :disabled {
      cursor: initial;
    }

    :disabled ~ ${StyledCheckmark} {
      background-color: ${p => p.theme.colors.grey};
      border: 1px solid ${p => p.theme.colors.lightGrey};
      :after {
        border-color: ${p => p.theme.colors.lightGrey};
      }
    }
  }
`;

export const Checkbox = ({ children, ...props }: any) => (
  <StyledLabel>
    <input type="checkbox" {...props} />
    <StyledChildren>{children}</StyledChildren>
    <StyledCheckmark />
  </StyledLabel>
);
