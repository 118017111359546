/**
 * Return a function, that, as long as it continues to be invoked, will
 * not be triggered. The function will be called after it stops being
 * called for `wait` milliseconds.
 * @callback debounceFunction
 * @param {debounceFunction} func - Function to call
 * @param {number} wait - Function call wait time in milliseconds
 * @returns {debounceFunction}
 */
export default function debounce<
  debounceFunction extends (...args: any) => any
>(func: debounceFunction, wait = 100 as number) {
  let timeout: number = 0;

  const debounced = (...args: any) => {
    clearTimeout(timeout);
    timeout = window.setTimeout(() => func(...args), wait);
  };

  return debounced as (
    ...args: Parameters<debounceFunction>
  ) => ReturnType<debounceFunction>;
}
