import React from 'react';
import styled from 'styled-components';
import { AuthState, withAuth } from 'context/auth';
import { AlertsState } from 'context/alerts';
import {
  Button,
  Checkbox,
  Jumbotron,
  ContentArea,
  SandboxInfo,
  SandboxTerms,
  Modal,
  UnstyledButton,
} from 'components';
import { postAcceptTerms } from 'utils/api';

const StyledWelcome = styled.h2`
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 26px;
  text-align: center;
`;
const StyledTitle = styled.h1`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 76px;
  line-height: 1;
  text-align: center;
`;
const StyledDarkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${p => p.theme.colors.darkGreyTransparent};
  padding: 30px 50px;
  border: 1px solid ${p => p.theme.colors.grey};
  max-width: 700px;
  min-width: 600px;

  h3 {
    font-size: 26px;
    margin-bottom: 42px;
  }
`;
const StyledAcceptTerms = styled.div`
  margin-left: auto;
  margin-right: auto;
`;
const StyledOpenSandboxTermsModal = styled(UnstyledButton)`
  margin-top: 84px;
  outline: none;
  font-size: 16px;
  text-decoration: underline;
`;
const StyledAcceptTermsButton = styled(Button)`
  margin-top: 42px;
`;
const StyledDarkContainerHeader = styled.h3`
  margin-top: 0;
`;

interface Props {
  auth: AuthState;
  history: any;
  alerts: AlertsState;
}

interface State {
  termsAccepted: boolean;
  loading: boolean;
  userHasAcceptedTerms: boolean;
  modalOpen: boolean;
}

class SandboxPage extends React.Component<Props, State> {
  readonly state = {
    termsAccepted: false,
    loading: false,
    userHasAcceptedTerms: false,
    modalOpen: false,
  };

  async acceptTerms(termsAccepted: boolean, sandboxId: string) {
    if (!termsAccepted) {
      return;
    }
    this.setState({
      loading: true,
    });

    try {
      await postAcceptTerms(this.props.auth, sandboxId);

      this.setState(
        {
          termsAccepted: true,
          userHasAcceptedTerms: true,
          loading: false,
        },
        () => this.props.auth.acceptSandboxTerms()
      );
    } catch (err) {
      this.setState(
        {
          loading: false,
        },
        () => this.props.alerts.setMessage(err, 'danger')
      );
    }
  }

  renderTermsModal() {
    const sandbox = this.props.auth.sandbox;
    if (
      sandbox?.termsAccepted === false &&
      this.state.userHasAcceptedTerms === false
    )
      return null;

    const mailtoLink = `mailto:${this.props.auth.sandbox?.themeInformation?.helpdesk_email}`;
    return (
      <div>
        <StyledOpenSandboxTermsModal
          onClick={() => this.setState({ modalOpen: true })}
        >
          View {process.env.REACT_APP_SANDBOX_NAME} Terms of Use
        </StyledOpenSandboxTermsModal>
        <Modal
          isOpen={this.state.modalOpen}
          onClose={() => this.setState({ modalOpen: false })}
        >
          <SandboxTerms loadTermsOnInit />
        </Modal>
        <p>
          Contact us, <a href={mailtoLink}>{this.props.auth.sandbox?.themeInformation?.helpdesk_email}</a>
        </p>
      </div>
    );
  }

  renderLowerHalf() {
    const sandbox = this.props.auth.sandbox;
    if (
      sandbox?.termsAccepted === false &&
      this.state.userHasAcceptedTerms === false
    ) {
      return (
        <StyledDarkContainer id="sandbox-dark-container">
          <StyledAcceptTerms>
            <SandboxTerms>
              <Checkbox
                checked={this.state.termsAccepted}
                onChange={(e: any) =>
                  this.setState({ termsAccepted: e.target.checked })
                }
                disabled={this.state.loading}
              >
                I accept {process.env.REACT_APP_SANDBOX_NAME} Terms of Use
              </Checkbox>
              <StyledAcceptTermsButton
                onClick={() =>
                  this.acceptTerms(this.state.termsAccepted, sandbox.id)
                }
                disabled={!this.state.termsAccepted || this.state.loading}
              >
                Accept
              </StyledAcceptTermsButton>
            </SandboxTerms>
          </StyledAcceptTerms>
        </StyledDarkContainer>
      );
    }

    return (
      <StyledDarkContainer id="sandbox-dark-container">
        <StyledDarkContainerHeader>
          Genome and phenome data and variety of tools are waiting
        </StyledDarkContainerHeader>
        <Button
          onClick={() => {
            this.props.history.push(`/${this.props.auth.sandbox?.id}/vm`);
          }}
        >
          Open sandbox desktop
        </Button>
        {this.renderTermsModal()}
      </StyledDarkContainer>
    );
  }

  renderUpperHalf() {
    const title =
      this.props.auth.sandbox?.themeInformation?.title ||
      this.props.auth.sandbox?.name;
    return (
      <>
        <StyledWelcome id="sandbox-welcome-text">Welcome to</StyledWelcome>
        <StyledTitle id="sandbox-title">{title}</StyledTitle>
      </>
    );
  }

  render() {
    return (
      <>
        <Jumbotron
          loading={this.props.auth.loggedIn === undefined}
          upperHalf={this.renderUpperHalf()}
          lowerHalf={this.renderLowerHalf()}
          backgroundImage={
            this.props.auth.sandbox?.themeInformation?.background_url
          }
        />
        <div id="sandbox-content-area">
          <ContentArea>
            <SandboxInfo auth={this.props.auth} sandboxId={this.props.auth.sandboxId}/>
          </ContentArea>
        </div>
      </>
    );
  }
}
export default withAuth(SandboxPage);
