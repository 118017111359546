import React from 'react';
import styled from 'styled-components';
import { withAuth, AuthState } from 'context/auth';
import { Input, Textarea, Button, UnstyledButton } from 'components';
import { slideUp } from 'styles/animations';
import { postErrorReport } from 'utils/api';

const StyledContainer = styled.div<{ visible: boolean }>`
  width: 600px;
  position: absolute;
  top: 12px;
  right: ${p => (p.visible ? '12px' : '-600px')};
  opacity: ${p => (p.visible ? 1 : 0)};
  background-color: ${p => p.theme.colors.white};
  border: 1px solid ${p => p.theme.colors.black};
  z-index: ${p => (p.visible ? '100' : '-1')};
  visibility: ${p => (p.visible ? 'visible' : 'hidden')};
  transition: all 0.2s ease, opacity 0.1s ease;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  -webkit-backface-visibility: hidden;
  padding: 24px;
`;
const StyledFormHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
`;
const StyledTitle = styled.h3`
  margin: 0;
`;
const StyledCloseButton = styled.button`
  appearance: none;
  background-color: ${p => p.theme.colors.lightGrey};
  border: 1px solid ${p => p.theme.colors.black};
  color: ${p => p.theme.colors.black};
  transition: all 0.2s;
  margin-left: auto;
  width: 26px;
  height: 26px;
  font-size: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;

  &:hover,
  &:focus {
    background-color: ${p => p.theme.colors.grey};
  }
`;
const StyledButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const StyledResetButton = styled(UnstyledButton)`
  display: inline-block;
  text-decoration: none;
  outline: none;
  transition: background-color 0.2s ease;
  color: ${p => p.theme.colors.black};
  border: 1px solid ${p => p.theme.colors.grey};
  background-color: ${p => p.theme.colors.white};
  padding: 12px 18px;
  border-radius: 20px;
  min-width: 140px;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  margin-right: 12px;

  :hover:not([disabled]) {
    background-color: ${p => p.theme.colors.grey};
  }
  &[disabled] {
    cursor: default;
    color: ${p => p.theme.colors.grey};
  }
`;
const StyledAlertBox = styled.div<{ alertType?: string }>`
  ${slideUp}
  padding: 12px 12px 12px 24px;
  border-radius: 2px;
  margin: 20px auto 0 auto;
  border: 1px solid transparent;
  width: 100%;
  min-height: 70px;
  display: flex;
  align-items: center;

  color: ${p => (p.alertType === 'success' ? '#155724' : '#721c24')};
  background-color: ${p => (p.alertType === 'success' ? '#d4edda' : '#f8d7da')};
  border-color: ${p => (p.alertType === 'success' ? '#c3e6cb' : '#f5c6cb')};
`;
const StyledNote = styled.p`
  margin: 0;
  font-size: 14px;
  margin-bottom: 40px;
`;

interface Props {
  visible: boolean;
  onClose(): void;
  auth: AuthState;
  profile: gapi.auth2.BasicProfile;
}

interface State {
  textareaValue: string;
  submittingForm: boolean;
  formError: string | undefined;
  submitSuccess: boolean | undefined;
}

class _ErrorReportingForm extends React.Component<Props, State> {
  readonly state = {
    textareaValue: '',
    submittingForm: false,
    formError: undefined,
    submitSuccess: undefined,
  };

  constructor(props: any) {
    super(props);

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(event: React.ChangeEvent<HTMLTextAreaElement>): void {
    this.setState({
      textareaValue: event.target.value,
      formError: undefined,
    });
  }

  closeForm() {
    this.props.onClose();
  }

  async submitForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!this.state.textareaValue) {
      this.setState({ formError: 'Please fill the required fields!' });
      return false;
    }

    this.setState({ submittingForm: true });

    try {
      const report = {
        name: this.props.profile.getName(),
        username: this.props.profile.getEmail(),
        error_description: this.state.textareaValue,
      };
      await postErrorReport(this.props.auth, report);
      this.setState(
        {
          submitSuccess: true,
          submittingForm: false,
          formError: undefined,
          textareaValue: '',
        },
        () =>
          setTimeout(() => {
            this.setState({ submitSuccess: undefined });
            this.closeForm();
          }, 3000)
      );
    } catch (err) {
      this.setState({
        formError: 'Error when sending report. Please try again later.',
        submittingForm: false,
      });
    }
  }

  render() {
    return (
      <StyledContainer visible={this.props.visible}>
        <StyledFormHeader>
          <StyledTitle>Report an error</StyledTitle>
          <StyledCloseButton onClick={() => this.closeForm()}>
            &times;
          </StyledCloseButton>
        </StyledFormHeader>

        <form onSubmit={event => this.submitForm(event)}>
          <Input
            disabled
            name="name"
            type="text"
            labelText="Name"
            value={this.props.profile.getName()}
          />
          <Input
            disabled
            name="username"
            type="text"
            labelText="Username"
            value={this.props.profile.getEmail()}
          />
          <Textarea
            name="error_description"
            rows="10"
            labelText="Please describe your problem and what you were doing"
            required
            value={this.state.textareaValue}
            onChange={this.handleOnChange}
            disabled={this.state.submittingForm}
          />
          <StyledNote>
            NOTE: We will collect required timestamps and error mesages from
            last 24h and attach them automatically to report.
          </StyledNote>
          <StyledButtonRow>
            <StyledResetButton
              disabled={!this.state.textareaValue || this.state.submittingForm}
              onClick={() =>
                this.setState({
                  textareaValue: '',
                  formError: undefined,
                  submitSuccess: undefined,
                })
              }
            >
              Clear form
            </StyledResetButton>
            <Button type="submit" disabled={this.state.submittingForm}>
              {this.state.submittingForm ? 'Submitting...' : 'Submit'}
            </Button>
          </StyledButtonRow>
        </form>
        {this.state.formError !== undefined && (
          <StyledAlertBox alertType="danger">
            {this.state.formError}
          </StyledAlertBox>
        )}
        {this.state.submitSuccess === true && (
          <StyledAlertBox alertType="success">
            Report has been sent succesfully!
          </StyledAlertBox>
        )}
      </StyledContainer>
    );
  }
}

const ErrorReportingForm = withAuth(_ErrorReportingForm);

export { ErrorReportingForm };
