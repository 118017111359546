import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

// internal
import { AuthState, withAuth } from 'context/auth';
import { withAlerts, AlertsState } from 'context/alerts';
import {
  Button,
  LoadingIndicator,
  LoginButton,
  Jumbotron,
  ContentArea,
  SandboxInfo,
} from 'components';
import { slideUp } from 'styles/animations';

const StyledWelcome = styled.h2`
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 26px;
  text-align: center;
`;
const StyledTitle = styled.h1`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 76px;
  line-height: 1;
  text-align: center;
`;
const StyledDescription = styled.p`
  text-align: center;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 20px;
  max-width: 440px;
`;
const StyledSandboxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${p => p.theme.colors.darkGreyTransparent};
  padding: 30px 50px;
  border: 1px solid ${p => p.theme.colors.grey};
  max-width: 700px;
  min-width: 600px;
  ${slideUp}

  > p {
    margin-top: 0;
    font-size: 18px;
    text-align: center;
  }

  ${Button} {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

interface Props {
  auth: AuthState;
  history: any;
  alerts: AlertsState;
}

interface State {
  loading: boolean;
}

class WelcomePage extends React.Component<Props, State> {
  readonly state = {
    loading: false,
  };

  componentDidUpdate() {
    // Wait until user has logged in and check sandboxes after it.
    // If user has only one sandbox, redirect straight to it
    if (this.props.auth.loggedIn === true) {
      if (this.props.auth.sandboxesAvailable.length > 1) return;

      this.props.history.replace(
        `/${this.props.auth.sandboxesAvailable[0].id}`
      );
    }
  }

  renderLowerHalf() {
    const auth = this.props.auth;
    if (auth.loggedIn === undefined && !this.props.alerts.message) {
      return <LoadingIndicator />;
    }

    if (auth.loggedIn === false) {
      return (
        <>
          <StyledDescription>
            Login with your {process.env.REACT_APP_SANDBOX_DOMAIN} account.
          </StyledDescription>
          <LoginButton />
        </>
      );
    }

    if (auth.loggedIn === true) {
      return (
        <>
          <StyledSandboxesContainer id="dark-container">
            <StyledDescription>
              You have been logged in succesfully!
            </StyledDescription>
            <StyledDescription>Please select a sandbox:</StyledDescription>
            {auth.sandboxesAvailable.map((sandbox: any) => (
              <Button
                key={sandbox.id}
                type="button"
                onClick={() => {
                  this.props.history.push(`/${sandbox.id}`);
                }}
              >
                {sandbox.name}
              </Button>
            ))}
          </StyledSandboxesContainer>
        </>
      );
    }
  }

  renderUpperHalf() {
    return (
      <>
        <StyledWelcome id="welcome-text">Welcome to</StyledWelcome>
        <StyledTitle id="welcome-title">{process.env.REACT_APP_SANDBOX_NAME}</StyledTitle>
      </>
    );
  }

  render() {
    return (
      <>
        <Jumbotron
          upperHalf={this.renderUpperHalf()}
          lowerHalf={this.renderLowerHalf()}
        />
        <div id="content-area">
          <ContentArea>
            <SandboxInfo auth={this.props.auth} sandboxId={this.props.auth.sandboxId}/>
          </ContentArea>
        </div>
      </>
    );
  }
}
export default withRouter(withAuth(withAlerts(WelcomePage)));
